import React, { useState, useEffect } from 'react'
import Classes from './styles/style.module.css'
import {
    Button,
    ListItem,
    ListItemIcon,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { AnimateTopToBottom } from '../../Pages/Home-New/Components/Animations/AnimateTopToBottom';
import StripeCheckout from 'react-stripe-checkout';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import backend from '../../api';
import { setUserData } from "../../redux-store/auth/actions";
import axios from 'axios';


function LearnMorePopup({ closeUpgradePopup, closeable }) {
    const [isYearly, setIsYearly] = useState(false);

    const handleBgClick = () => {
        if (closeable) {
            closeUpgradePopup();
        }
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    return (
        <div className={Classes.popup} onClick={handleBgClick} >
            <div className={Classes.card} onClick={(event) => { handleCardClick(event) }} >
                {closeable &&
                    <div className={Classes.close} onClick={closeUpgradePopup} >
                        <img src='/icons/close.png' alt='close icon' />
                    </div>
                }
                <div className={Classes.header} >
                    <img src='/icons/upgrade-round.png' alt='upgrade-icon' />
                    <h2 className={Classes.planName}>Free year of premium</h2>
                    <h5>
                        Score 90 or above on your pitch deck and unlock a full year of premium features! 
                        <br />
                        <br />
                        Simply tag our LinkedIn account with a picture of your impressive score to claim your reward!
                    </h5>
                </div>
                <a href='https://www.linkedin.com/company/pitchperfecter-ai/' target='_blank' >
                    <button className={Classes.premiumBtn} >LinkedIn</button>
                </a>
            </div>
        </div>
    )
}

export default LearnMorePopup
