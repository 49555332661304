import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
// import "./Nav.css"; // Import the CSS file
import Menu from "./Menu";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../redux-store";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AuthPopup from "./auth-pop/Popup";

import PersonIcon from '@mui/icons-material/Person';
import Classes from './Nav.module.css'
import { motion, easeOut } from 'framer-motion';

function Nav() {
  const { user } = useSelector((state) => state.auth);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleOpenAuth = () => setOpen(true);
  const ProjectID = localStorage.getItem("projectID")

  const handleCloseAuth = () => {
    setOpen(false);
  };

  const navigateTo = useNavigate();

  const handleLogout = async () => {
    localStorage.clear();
    navigateTo("/");
    await dispatch(authActions.logout());
    toast.success("Signed out successfully");
  };

  const navigateToDashboard = () => {
    navigateTo(`/dashboard/`)
  }

  const navigateToLogin = () => {
    navigateTo('/login');
  }
  const navigateToSignup = () => {
    navigateTo('/login', { state: { data: "toSignup" } });
  }
  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const location = useLocation();

  const handleClick = (value) => {
    if (location.pathname === '/terms-of-service' || location.pathname === '/privacy-policy' || location.pathname === '/contact-us') {
      localStorage.setItem('navScroll', value);
      navigateTo('/');
    } else {
      scrollToElement(value);
    }
  }

  const scrollToElement = (elementId) => {
    const targetElement = document.getElementById(elementId);

    if (targetElement) {
      const offset = 50;
      const targetPosition = targetElement.offsetTop + offset;

      window.scroll({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };

  const isShowBoxShadow = () => {
    return location.pathname.includes("/contact-us") || location.pathname.includes("/privacy-policy") || location.pathname.includes("/terms-of-service");
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.3,
        ease: easeOut,
        delay: 1,
      }}
    >
      <div className={Classes.navContainer} style={{ boxShadow: isShowBoxShadow() ? '0px 4px 20px rgba(0, 0, 0, 0.1)' : '' }} >
        <div>
          <a className={Classes.logoLink} href="/">
            <img className={Classes.logoImage} src='Blue.png' alt="Logo" />
          </a>
        </div>
        <nav className={Classes.navMenu}>
          <a onClick={() => handleClick("how-it-works")}>
            How it Works
          </a>
          <a onClick={() => handleClick("features")}>
            Features
          </a>
          <a onClick={() => handleClick("pricing")}>
            Pricing
          </a>
          <a onClick={() => handleClick("faq")}>
            FAQ
          </a>
        </nav>
        <div>
          {user && <a className={Classes.dashboardLink} href="/dashboard" onClick={() => navigateToDashboard()}>
            <PersonIcon className={Classes.dashboardIcon} />
          </a>}
          {!user && (
            <div className={Classes.authButtons}>
              <a onClick={navigateToLogin} >
                <button className={Classes.signInButton}>
                  Sign in
                </button>
              </a>
              <a onClick={navigateToSignup} >
                <button className={Classes.tryFreeButton}>
                  Sign up
                </button>
              </a>
            </div>
          )}
        </div>
        {open && <AuthPopup onClose={handleCloseAuth} />}
      </div>
    </motion.div>
  );
}

export default Nav;
