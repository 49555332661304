import React from 'react'
import Classes from './PitchAnalyzer.module.css'

function PageSelctorComp({imageUrl,index,pageIndex,setpageIndex}) {
    const handleClick = ()=>{
        setpageIndex(index);
    }
    return (
        <>
            <div className={Classes.listBox} onClick={handleClick} style={{border: pageIndex===index? '2px solid #0262DF':''}} >
                <div className={Classes.boxImgCon} >
                    <img src={imageUrl} />
                </div>
                <h5> Slide {index+1} </h5>
            </div>
        </>
    )
}

export default PageSelctorComp