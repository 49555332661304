import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Menu from './Global-Components/Menu';
import Footer from './Global-Components/Footer/Footer';
import Auth from './Pages/Auth/Auth';
import ResponsiveDrawer from './Global-Components/Sidebar';
import Home from './Pages/Home/Home';
import PitchDeckGen from './Pages/Pitch-Deck-gen/Pitch-Deck-gen';
import PitchQuestions from './Pages/Pitch-Deck-gen/PitchQuestions';
import Inbox from "./Pages/Account/Inbox/Inbox";
import PitchAnalyzer from './Pages/Pitch-Analyzer/Pitch-Analyzer';
import PitchEditor from './Pages/Pitch-Editor/Pitch-Editor';
import HomeNew from './Pages/Home-New/Home-New';
import PitchDashboard from './Pages/Pitch-Dashboard/Pitch-Dashboard';
import PitchTemplates from './Pages/Pitch-Templates/Pitch-Templates';
import PitchPDFanalyzer from './Pages/Pitch-Analyzer/Pitch-Analyzer-pdf';
import Testing from './Pages/Pitch-Analyzer/Testing';
import Testing1 from './Pages/Pitch-Analyzer/Testing1';
import LoadingLineWithPercentage from './Pages/Pitch-Analyzer/Loader';
import ContactUs from './Pages/ContactUs/ContactUs';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfService from './Pages/TermsOfService/TermsOfService';
import SidebarForAnalyzer from './Global-Components/SidebarForAnalyzer';
import ForgotPassword from './Pages/Auth/ForgotPassword';
import LiveDemo from './Pages/LiveDemo/LiveDemo';
import { Analytics } from '@vercel/analytics/react';


const AnalyzerWithSideBar = (Component) => (
  <>
    <SidebarForAnalyzer component={<Component />} />
  </>
);



const RenderRouterWithSideBar = (Component) => (
  <>
    <ResponsiveDrawer component={<Component />} />
  </>
);

const RenderRouterWithMenuFooter = (Component) => (
  <>
    <Menu />
    <Component />
    <Footer />
  </>
);

const RenderRouterWithMenuOnly = (Component) => (
  <>
    <Menu />
    <Component />
  </>
);

const RenderRouterWithFooterOnly = (Component) => (
  <>
    <Component />
    <Footer />
  </>
);

const RenderRouterOnly = (Component) => (
  <>
    <Component />
  </>
);

const LoggedInRoutes = () => (
  <>
    <Routes>
      <Route path="/" element={RenderRouterWithSideBar(PitchDashboard)} />
      <Route path="/chatbot/:id/" element={RenderRouterWithSideBar(Inbox)} />
      <Route path="/chatbot" element={RenderRouterWithSideBar(Inbox)} />
      <Route path="/pitchgenerator" element={RenderRouterWithMenuOnly(PitchDeckGen)} />
      <Route path="/pitchquestions" element={<PitchQuestions />} />
      <Route path="/analyzer" element={AnalyzerWithSideBar(Testing1)} />
      <Route path="/analyzer/:id" element={AnalyzerWithSideBar(Testing1)} />

      <Route path="/pitcheditor" element={RenderRouterWithSideBar(PitchEditor)} />
      <Route path="/pitchquestions" element={<PitchQuestions />} />
      <Route path="/dashboard" element={RenderRouterWithSideBar(PitchDashboard)} />
      <Route path="/templates" element={RenderRouterWithSideBar(PitchTemplates)} />
      <Route path="/testing" element={RenderRouterWithSideBar(Testing)} />
      <Route path="/testing1" element={RenderRouterWithSideBar(Testing1)} />
      <Route path="/home" element={RenderRouterWithMenuFooter(HomeNew)} />
      <Route path="/contact-us" element={RenderRouterWithFooterOnly(ContactUs)} />
      <Route path="/privacy-policy" element={RenderRouterWithMenuFooter(PrivacyPolicy)} />
      <Route path="/terms-of-service" element={RenderRouterWithMenuFooter(TermsOfService)} />

      <Route path="*" element={RenderRouterWithMenuFooter(HomeNew)} />

    </Routes>
  </>
);

const NotLoggedInRoutes = () => (
  <>

    <Routes>
      <Route path="/" element={RenderRouterWithMenuFooter(HomeNew)} />
      <Route path="/login" element={<Auth />} />
      <Route path="/createpassword/:id" element={<ForgotPassword />} />
      <Route path="/pitchgenerator" element={RenderRouterWithMenuOnly(PitchDeckGen)} />
      <Route path="/contact-us" element={RenderRouterWithMenuFooter(ContactUs)} />
      <Route path="/privacy-policy" element={RenderRouterWithMenuFooter(PrivacyPolicy)} />
      <Route path="/terms-of-service" element={RenderRouterWithMenuFooter(TermsOfService)} />
      <Route path="/home" element={RenderRouterWithMenuFooter(HomeNew)} />
      <Route path="/demo" element={RenderRouterOnly(LiveDemo)} />
      <Route path="*" element={RenderRouterWithMenuFooter(HomeNew)} />
    </Routes>

  </>
);

function AppRoutes() {
  const { user } = useSelector((state) => state.auth);

  return (
    <BrowserRouter>
      {user ? <LoggedInRoutes /> : <NotLoggedInRoutes />}
      <Analytics/>
    </BrowserRouter>
  );
}

export default AppRoutes;
