import React, { useState, useEffect } from "react";
import GoogleIcon from '@mui/icons-material/Google';
import { authActions } from "../../redux-store";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import sessionstorage from "sessionstorage";
import backend from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { motion, easeOut } from 'framer-motion';
import Classes from './Auth.module.css'

function ForgotPassword() {
    const [width, setWidth] = useState(window.innerWidth);
    const { user } = useSelector((state) => state.auth);
    const [password, setPassword] = useState("");
    const [confirmPassword, setconfirmPassword] = useState("");
    const [loading, setLoading] = useState();
    const [incorrectError, setincorrectError] = useState(false);
    const navigateTo = useNavigate();
    const [showEye, setshowEye] = useState(false);
    const [showEye2, setshowEye2] = useState(false);
    const [passwordMatchingError, setpasswordMatchingError] = useState(false);
    const [passwordLengthError, setpasswordLengthError] = useState(false);
    const [passwordSuccessMsg,setpasswordSuccessMsg] = useState(false);
    const location = useLocation();
    const { id } = useParams();
    console.log(id)

    const updateDimensions = () => {
        setWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);



    const handlePasswordUpdate = async () => {
        const body = { password: password };
        console.log(body);
        try {
          const response = await backend.put(`/api/user/changepassword/${id}`, body);
          console.log(response)
          if(response) {
            setpasswordSuccessMsg(true);
            setTimeout(() => {
                setpasswordSuccessMsg(false);
                navigateTo('/login');
            }, 3000);
          }
        } catch (error) {
          console.error("Failed to post data to server", error);
          toast.error("Failed to save data");
        }
      };
  
    const handleSubmit = () => {
        setincorrectError(false);
        if (password !== confirmPassword) {
            setpasswordMatchingError(true);
            setTimeout(() => {
                setpasswordMatchingError(false);
            }, 5000);
            return;
        }

        if (password && password.length <= 5) {
            setpasswordLengthError(true);
            setTimeout(() => {
                setpasswordLengthError(false);
            }, 10000);
            return;
        }

        handlePasswordUpdate();
    }

    return loading ? <div
        style={{
            display: "flex",
            justifyContent: "center",
            height: "100vh",
            alignItems: "center",
        }}
    >
        <CircularProgress />
    </div> : (
        <>
            <div className={Classes.backgroundImage} >
                <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        duration: 0.3,
                        ease: easeOut,
                        delay: 1,
                    }}
                >
                    <div className={Classes.navContainer} style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} >
                        <div>
                            <a className={Classes.logoLink} href="/">
                                <img className={Classes.logoImage} src='/Blue.png' alt="Logo" />
                            </a>
                        </div>
                        <nav className={Classes.navMenu}>

                        </nav>
                        <div>
                            <a href="/">
                                <h5 className={Classes.goBackTxt} >Go back to PitchPerfecter.ai</h5>
                            </a>
                        </div>
                    </div>

                </motion.div>
                <section className={`${Classes.section}`}>
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                            duration: 0.3,
                            ease: easeOut,
                            delay: 1,
                        }}
                    >
                        <div className={Classes.boxStyles}>
                            <a href="/" className={Classes.miniLogo} >
                                <img src="/site.png" alt="logo" />
                            </a>
                            <h1 className={Classes.heading}>Change Password</h1>
                            <p className={Classes.subtitle}>You can create new password now</p>
                            <br />
                            <div className={`${Classes.passwordCon} ${incorrectError || passwordMatchingError || passwordLengthError ? Classes.inputError : ''}`} >
                                <input
                                    onChange={(e) => setPassword(e.target.value)}
                                    type={showEye ? "text" : "password"}
                                    placeholder="Create new password"
                                    required
                                />
                                {showEye ?
                                    <>
                                        <svg onClick={() => { setshowEye(!showEye) }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>
                                    </>
                                    :
                                    <>
                                        <svg onClick={() => { setshowEye(!showEye) }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm51.3 163.3l-41.9-33C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5zm-88-69.3L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8z" /></svg>
                                    </>
                                }
                            </div>
                            <div className={`${Classes.passwordCon} ${incorrectError || passwordMatchingError || passwordLengthError ? Classes.inputError : ''}`} >
                                <input
                                    onChange={(e) => setconfirmPassword(e.target.value)}
                                    type={showEye2 ? "text" : "password"}
                                    placeholder="Confirm new password"
                                    required
                                />
                                {showEye2 ?
                                    <>
                                        <svg onClick={() => { setshowEye2(!showEye2) }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>
                                    </>
                                    :
                                    <>
                                        <svg onClick={() => { setshowEye2(!showEye2) }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm51.3 163.3l-41.9-33C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5zm-88-69.3L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8z" /></svg>
                                    </>
                                }
                            </div>
                            {passwordMatchingError && <p className={Classes.error}>*Passwords are not matching.</p>}
                            {passwordLengthError && <p className={Classes.error}>*Enter correct email & password length greater than 5.</p>}
                            {passwordSuccessMsg && <p className={Classes.success}>*Password updated successfully.</p>}
                            <button onClick={() => handleSubmit()} className={`${Classes.button} ${Classes.signInButton}`}>
                                Submit
                            </button>
                        </div>
                    </motion.div>
                </section>
            </div>
        </>
    );
}

export default ForgotPassword;
