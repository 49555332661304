import React, { useState, useEffect } from "react";
import Classes from './LiveDemo.module.css';
import SummaryPopup from "./SummaryPopup";
import PageSelctorComp from "./PageSelctorComp";
import MobileVersionPopup from "../../Global-Components/MobileVersionPopup/MobileVersionPopup";
import Loading from "../../Global-Components/Loading/Loading";
import { useNavigate } from "react-router-dom";

const styles = {
    fontFamily: "Manrope, sans-serif",
    fontSize: "16px",


    color: "black",
    whiteSpace: "pre-wrap", // allow wrapping of long lines
    wordWrap: "break-word", // break long words

};

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto", // Adjust width as needed
    maxWidth: "70vw", // Maximum width of the modal is 80% of the viewport width
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflowY: "auto", // Enable scrolling if content is too long
    maxHeight: "65vh", // Maximum height is 80% of the viewport height
    // margin: "5vh", // Adds margin to the top & bottom of the modal (5% of the viewport height)
    borderRadius: "15px",
    outline: 'none',
};

function LiveDemo() {
    const Navigate = useNavigate();
    const [loading, setloading] = useState(true);
    const [openSummary, setopenSummary] = useState(false);

    const handleCloseSummary = () => {
        setopenSummary(false);
    }

    const [UberDeck, setUberDeck] = useState(
        [
            {
                name: 'Deck 1',
                imageUrl: '/demo/Uber-01.jpg',
                feedback: `Your first slide effectively showcases your logo and slogan. The minimalist design ensures that the core branding elements are front and center, which is crucial for making a memorable first impression.`,
                suggestions: `Consider adding a subtle animation or a dynamic background to make the slide more engaging. Additionally, a brief tagline that communicates your unique value proposition could enhance the impact of your branding.`,
            },
            {
                name: 'Deck 2',
                imageUrl: '/demo/Uber-02.jpg',
                feedback: `The current logo design effectively uses minimalistic elements and a clear color palette which enhances brand recognition.`,
                suggestions: `Consider incorporating a subtle element that reflects the company's industry or values to add depth to the design. Additionally, testing the logo in different sizes and formats to ensure its versatility would be beneficial.`,
            },
            {
                name: 'Deck 3',
                imageUrl: '/demo/Uber-03.jpg',
                feedback: `The slide is currently empty, which indicates a focus on the logo design. It is crucial to maintain a minimalist approach while ensuring the logo is visually appealing and effectively communicates the brand identity.`,
                suggestions: `Consider incorporating the company's core values or primary services into the logo design to make it more meaningful and representative of the brand. It's also recommended to use colors that align with the brand's palette to enhance recognition and consistency across all platforms. Additionally, choosing a clear and easily readable typography can improve the logo's visibility and impact.`,
            },
            {
                name: 'Deck 4',
                imageUrl: '/demo/Uber-04.jpg',
                feedback: `The slide effectively conveys the importance of a well-designed logo in brand identity. It would be beneficial to include examples of successful logos to provide clearer context and inspiration.`,
                suggestions: `Consider adding a visual component to the slide that showcases different logo design trends or styles. This can help in visually communicating the type of branding the company aims to achieve. Additionally, providing guidelines or criteria for what makes an effective logo could be useful for aligning the design with the company’s branding objectives.`,
            },
            {
                name: 'Deck 5',
                imageUrl: '/demo/Uber-05.jpg',
                feedback: `The absence of additional information or visuals directs complete attention to the logo, maximizing impact but missing an opportunity to communicate brand values or messages directly through supplementary text or design elements.`,
                suggestions: `Consider integrating a brief tagline or descriptor that aligns with the logo to enhance brand messaging. Additionally, incorporating subtle background colors or patterns that reflect the brand palette could increase visual appeal without detracting from the logo's prominence.`,
            },
            {
                name: 'Deck 6',
                imageUrl: '/demo/Uber-06.jpg',
                feedback: `The current slide effectively spotlights the logo, which is essential for brand recognition. However, without accompanying text or context, it might not fully convey the intended message or values of the brand to the audience.`,
                suggestions: `Consider adding a brief tagline or statement that encapsulates the brand's mission or vision alongside the logo. This will provide immediate context and enhance the logo’s impact. Additionally, ensure that the logo is displayed with high resolution and is visually centered to maintain professional aesthetics. Exploring the use of background colors that complement or contrast with the logo might also enhance its visibility and appeal.`,
            },
            {
                name: 'Deck 7',
                imageUrl: '/demo/Uber-07.jpg',
                feedback: `The slide effectively utilizes minimalism which can be appealing and memorable. However, the absence of additional information might leave viewers needing more context or detail about the brand or product.`,
                suggestions: `Consider adding a tagline or a subtle design element that communicates the brand’s core message or value proposition. This can enhance the logo's impact without compromising the design's simplicity. Additionally, incorporating a brief introduction or mission statement could provide valuable context and connect better with the audience. This balance will likely make the pitch more comprehensive and engaging.`,
            },
            {
                name: 'Deck 8',
                imageUrl: '/demo/Uber-08.jpg',
                feedback: `The logo’s simplicity makes it highly versatile and scalable, suitable for various applications including digital and print. However, more emphasis on distinctiveness might help in standing out more prominently in a competitive market.`,
                suggestions: `Consider incorporating an element that highlights a unique aspect of the brand or experiment with a slightly bolder color palette to increase visibility. Also, testing the logo in different contexts and against competitor visuals could provide further insights into its effectiveness.`,
            },
            {
                name: 'Deck 9',
                imageUrl: '/demo/Uber-09.jpg',
                feedback: `The logo is a critical aspect of branding and should effectively communicate the company's identity and values. A successful logo is distinctive, visually appealing, and reflective of the business's brand personality.`,
                suggestions: `Consider incorporating elements that represent the company’s industry and mission. Use colors and fonts that resonate with the target audience. A minimalist approach often works well, ensuring the logo is scalable and effective across various mediums and sizes. It's also beneficial to get feedback from a diverse group of stakeholders during the design process to ensure the logo meets its intended purpose and appeal.`,
            },
            {
                name: 'Deck 10',
                imageUrl: '/demo/Uber-10.jpg',
                feedback: `The slide appears to be intentionally left blank, possibly to emphasize the focus on a logo. This minimalistic approach can be effective if the logo itself carries significant brand recognition or impact. However, without additional context or descriptive elements, the slide may fail to communicate the intended message or brand values to the audience.`,
                suggestions: `Consider adding a brief tagline or a succinct descriptor that aligns with the logo. This can enhance the audience's understanding and retention of the brand's message or identity. Additionally, ensure the logo is visually clear and placed prominently to draw attention effectively.`,
            },
            {
                name: 'Deck 11',
                imageUrl: '/demo/Uber-11.jpg',
                feedback: `The logo design utilizes a clean and modern aesthetic, which appeals to the target demographic. The simplicity of the design ensures versatility across various media and applications. However, the logo might benefit from slight enhancements to increase its distinctiveness in a competitive market.`,
                suggestions: `Consider introducing a unique design element that differentiates the logo from competitors. This could be a subtle tweak in the color scheme or a creative integration of the brand's initials. Additionally, experimenting with typography to find a more unique font that still maintains readability could enhance the logo's impact. Conducting A/B testing with these variations could provide valuable insights into consumer preferences and the effectiveness of the logo.`,
            },
            {
                name: 'Deck 12',
                imageUrl: '/demo/Uber-12.jpg',
                feedback: `The logo effectively uses colors and design to evoke a sense of innovation and reliability which is essential for brand recognition. However, the complexity of the design might be challenging for smaller scales or when used in black and white formats.`,
                suggestions: `Consider simplifying the design to ensure it remains effective across various applications, including digital and print media. Additionally, testing the logo in different formats and sizes to ensure legibility and impact could enhance its versatility and effectiveness.`,
            },
            {
                name: 'Deck 13',
                imageUrl: '/demo/Uber-13.jpg',
                feedback: `The slide appears to be intentionally left blank to emphasize the importance of a logo design. This minimalistic approach suggests focusing solely on visual branding elements without additional textual or numerical information.`,
                suggestions: `Given the focus on the logo, it might be beneficial to explore various logo designs that effectively represent the brand identity. Consider conducting a survey or gathering feedback to determine how well potential logos resonate with your target audience. Additionally, ensure the logo is versatile enough to be effective across various platforms and scales well from large banners to small mobile screens. Keep the design simple yet memorable to make a lasting impression.`,
            },
        ]
    );

    const [Deck, setDeck] = useState(
        [
            {
                name: 'Deck 1',
                imageUrl: '/demo/1.jpg',
                feedback: `Your first slide effectively showcases your logo and slogan, setting a professional tone for the presentation.`,
                suggestions: `Consider including an illustration of your product or a dynamic visual element that highlights the innovative aspect of your vending machine to engage the audience right from the beginning.`
            },
            {
                name: 'Deck 2',
                imageUrl: '/demo/2.jpg',
                feedback: `The slide effectively presents critical concerns affecting the fast-food industry, including a significant average wait time of over 15 minutes, minimal adoption of technology in vending solutions, and inflated prices for healthy meal options, which average $13 and are subject to inflation pressures. These issues combined showcase a problematic landscape for consumers seeking quick and healthy options.`,
                suggestions: `Consider proposing solutions that address these specific problems. For example, enhancing the use of technology in food services can reduce wait times and streamline operations. Exploring partnerships with tech companies to develop intelligent vending machines and kiosks could also expand the market reach. Additionally, developing cost-effective strategies for sourcing and producing healthy meals might help in reducing the retail price, making it more accessible to a broader audience. Implementing these changes could significantly improve customer satisfaction and expand market share in the competitive fast-food industry.`
            },
            {
                name: 'Deck 3',
                imageUrl: '/demo/3.jpg',
                feedback: `The slide effectively outlines the unique selling propositions of the product, such as 24/7 operations, dietary flexibility, cost efficiency, and quick meal preparation. These points are likely to resonate well with individuals seeking convenient, healthy, and affordable meal options.`,
                suggestions: `To enhance the pitch, consider including specific data or testimonials that demonstrate customer satisfaction or operational success. Additionally, addressing potential concerns, such as food safety, meal variety, and machine maintenance, might provide a more rounded view of the product's benefits and operational logistics. Including information on the environmental impact of the product, if favorable, could also appeal to eco-conscious consumers.`
            },
            {
                name: 'Deck 4',
                imageUrl: '/demo/4.jpg',
                feedback: `The intelligent vending machine combines advanced technology with practical features to cater to modern fast-paced settings. The integration of a large touchscreen and robust microwave oven suggests a focus on user convenience and swift service.`,
                suggestions: `To further enhance the machine, consider implementing energy-efficient systems to reduce operating costs and support sustainability. Additionally, exploring options for customizable software could allow operators to tailor the interface and features to specific needs or locations.`
            },
            {
                name: 'Deck 5',
                imageUrl: '/demo/5.jpg',
                feedback: `Eat Fresco effectively highlights the quality and variety of their meal offerings, which can appeal to health-conscious consumers looking for convenient dining options with dietary considerations.`,
                suggestions: `Consider elaborating on the specific types of meals available to give potential customers a clearer idea of the menu. Additionally, highlighting any unique selling points or certifications, like organic or locally sourced ingredients, could further strengthen the brand's market appeal.`
            },
            {
                name: 'Deck 6',
                imageUrl: '/demo/6.jpg',
                feedback: `The slide delivers a concise overview of the critical functions of the back-end system, ensuring that potential clients or stakeholders can easily grasp how these features will benefit their operations.`,
                suggestions: `Consider adding specific examples or case studies that illustrate the improvements in efficiency or cost savings achieved through using your back-end system. This could help in demonstrating the tangible benefits and effectiveness of your platform to the audience.`
            },
            {
                name: 'Deck 7',
                imageUrl: '/demo/7.jpg',
                feedback: `Based on data in this slide, it appears that your business operates by partnering with Eat Fresco for food preparation and packaging. The meals are then delivered to a centralized cold-storage facility. You have a system in place for restocking based on custom reports and have a solution for excess meals by donating them to local food banks. Your revenue model involves selling meals at a price range of $8 to $10 and generating income through a fixed monthly rent or a percentage of gross revenue from the locations that host your vending machines. Your target markets include fulfillment centers, corporate offices, universities, airports, and hospitals. The plan for scaling involves adopting a franchising or licensing model to expand nationwide.`,
                suggestions: `To enhance the slide and make the business model clearer, consider including specific data or projections on expected revenue from the different hosting locations. It might also be helpful to provide case studies or pilot results if available, to illustrate the feasibility and success of the business model. Further detailing the partnership with Eat Fresco and the operational logistics could also strengthen the business case. Finally, explicitly mentioning any competitive advantages or unique selling points could help in distinguishing your service in the market.`
            },
            {
                name: 'Deck 8',
                imageUrl: '/demo/8.jpg',
                feedback: `The provided information highlights a clear growth trajectory in both the global and U.S. markets for vending machines, indicating a lucrative investment opportunity.`,
                suggestions: `Considering the high growth rates and substantial market sizes, potential investors should consider focusing on innovative solutions within the intelligent vending machine space to capture market share, particularly in underrepresented regions within the U.S., beyond just the Northeast and West Coast.`
            },
            {
                name: 'Deck 9',
                imageUrl: '/demo/9.jpg',
                feedback: `Based on data in this slide, the slide describes the competitive landscape for the company within the food service industry. It identifies three primary categories of competitors: other kiosks, delivery services, and restaurants. This categorization helps to clarify the segments of the market where competition is most intense. However, the slide lacks specific names or examples of competitors which could provide a clearer picture of the market dynamics.`,
                suggestions: `It would be beneficial to add specific examples of competitors within each identified category. This could include leading kiosks, popular delivery services, and major restaurant chains that pose a competitive threat. Additionally, incorporating a brief analysis of the strengths and weaknesses of these competitors could provide more strategic value to the presentation. Including market share information, if available, would also enhance the understanding of the competitive landscape. This data will give a more concrete sense of who the company is up against, and possibly identify gaps or opportunities in the market.`
            },
            {
                name: 'Deck 10',
                imageUrl: '/demo/10.jpg',
                feedback: `The presentation provides a clear and positive outlook on financial growth and efficiency improvements.`,
                suggestions: `It may be beneficial to include strategic initiatives that will drive these financial improvements to give stakeholders a complete view of the company's strategy moving forward.`
            },
            {
                name: 'Deck 11',
                imageUrl: '/demo/11.jpg',
                feedback: `The timeline for the initial launch is clear, and the phased approach (Beta Launch, Full Launch, Scaling Partnership) is strategically sound. However, details regarding the criteria for the proof of concept evaluation and specific metrics for success at each stage are missing.`,
                suggestions: `Consider defining clear success metrics for the beta launch to better evaluate its outcomes. Include potential locations and market demographics for the initial vending machines to attract relevant investors. Also, outline the specific support or partnerships needed at each stage to provide clarity for potential investors.`
            },
            {
                name: 'Deck 12',
                imageUrl: '/demo/12.jpg',
                feedback: `The slide effectively communicates essential contact details for further engagement. It is clear and straightforward, which is suitable for a closing slide.`,
                suggestions: `Consider adding a final remark or call-to-action to encourage the audience to reach out via the provided contact details. Additionally, if this presentation was delivered in a formal setting, ensure that the email and website are professionally designed to maintain consistency with the branding of 'fitoven.com'.`
            },
        ]
    );

    const goToLogin = () => {
        Navigate('/login');
    }

    const goToHome = () => {
        Navigate('/');
    }

    const [pageIndex, setpageIndex] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setloading(false);
        }, 2500);
    }, []);

    return (
        <div className={Classes.container} >
            <>
                {loading && <Loading />}
                <MobileVersionPopup />
                <div className={Classes.innerCon} >
                    <div className={Classes.leftCon} >
                        <div className={Classes.listCon} >
                            <div className={Classes.scroll1} >
                                <br />
                                <div className={Classes.goBack} onClick={goToHome} >
                                    {/* <svg onClick={goToHome}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
                                    <h5 onClick={goToHome} >Back to home</h5> */}
                                    <img src="/logoblue.png" />
                                </div>
                                {Deck.map((deck, index) => {
                                    return (<>
                                        <PageSelctorComp imageUrl={deck.imageUrl} key={index} index={index} pageIndex={pageIndex} setpageIndex={setpageIndex} />
                                    </>)
                                })
                                }
                                <br />
                            </div>
                        </div>
                    </div>
                    <div className={Classes.con1} >
                        <div className={Classes.swiperOuter} >
                            <div className={Classes.swiperCon} >
                                <div className={Classes.swiperUnloadedCon} >
                                    <img src={Deck[pageIndex].imageUrl} className={Classes.swiperImg} alt={`Sample Page 2`} />
                                </div>
                            </div>
                        </div>

                        <div className={Classes.boxesCon} >

                            <div className={Classes.feedbackCon} >
                                <div >
                                    <img src="/feedback.png" />
                                    <h3> Feedback </h3>
                                </div>
                                <p>
                                    <pre style={{
                                        ...styles,
                                        flex: "1",
                                        minWidth: "0",
                                        margin: '0',
                                    }}>
                                        {
                                            Deck[pageIndex].feedback
                                        }
                                    </pre>
                                </p>

                            </div>

                            <div className={Classes.suggestCon} >
                                <div >
                                    <img src="/icons/suggestion.png" />
                                    <h3> Suggestions </h3>
                                </div>
                                <p>
                                    <pre style={{
                                        ...styles,
                                        flex: "1",
                                        minWidth: "0",
                                        margin: '0',
                                    }}>
                                        {
                                            Deck[pageIndex].suggestions
                                        }
                                    </pre>
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className={Classes.scoreBoxCon} >
                        <div className={Classes.scoreBox} >
                            <h3 className="fw700" >Scoring breakdown</h3>
                            <h5 onClick={() => { setopenSummary(true) }} >Read full summary {`>`}</h5>
                            {openSummary &&
                                <SummaryPopup
                                    style={style}
                                    openSummary={openSummary}
                                    handleCloseSummary={handleCloseSummary}
                                />
                            }
                            <div className={Classes.padCon} >
                                <hr />
                                <div className={Classes.linesCon} >
                                    <div className={Classes.scoringGrid} >
                                        <div className={Classes.flex1} >
                                            <img src="/deck.png" alt="Image" />
                                            <h4 className={Classes.miniTitle} >Deck structure <span className={Classes.miniPerc} >(20%)</span> </h4>
                                        </div>
                                        <h4 className={Classes.miniTitle} > 78 </h4>
                                    </div>
                                    <div className={Classes.scoringGrid} >
                                        <div className={Classes.flex1} >
                                            <img src="/market.png" alt="Image" />
                                            <h4 className={Classes.miniTitle} >Market potential <span className={Classes.miniPerc} >(40%)</span> </h4>
                                        </div>
                                        <h4 className={Classes.miniTitle}> 88 </h4>
                                    </div>
                                    <div className={Classes.scoringGrid} >
                                        <div className={Classes.flex1} >
                                            <img src="/business.png" alt="Image" />
                                            <h4 className={Classes.miniTitle} >Business model <span className={Classes.miniPerc} >(40%)</span> </h4>
                                        </div>
                                        <h4 className={Classes.miniTitle} > 65 </h4>
                                    </div>
                                </div>
                            </div>
                            <div className={`${Classes.overallCon} ${Classes.padCon}`} >
                                <h4 className={Classes.miniTitle} > Overall deck score </h4>
                                <h4 className={Classes.miniTitle} >
                                    76.8
                                </h4>
                            </div>
                            <div className={`${Classes.templateOuter} ${Classes.padCon} `} >
                                <div className={Classes.templateCon} >
                                    <img src="/template.png" alt="Image" />
                                    <h4>Pitch deck template</h4>
                                    <h5>
                                        Download our ultimate pitch <br />
                                        deck template with over 125 <br />
                                        premium designed slides.
                                    </h5>
                                    <h4 className={Classes.download} onClick={goToLogin} >Download here</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
}

export default LiveDemo;
