import React, { useState, useEffect } from 'react';
import { Container, Stepper, Step, StepLabel, Button, Typography, TextField, Box } from '@mui/material';
import { Configuration, OpenAIApi } from "openai";
import { CircularProgress } from '@mui/material';
import PptxGenJS from "pptxgenjs";
import Loader from './Loader';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReplayIcon from '@mui/icons-material/Replay';
import TryIcon from '@mui/icons-material/Try';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckIcon from '@mui/icons-material/Check';

export default function PitchEditor() {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [resp, setResp] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState('Investor Pitch, Invester math, Venture glossary, VC glossary terms');
  const [slides, setSlides] = useState([]); // Hold slide images
  const [downloading, setDownloading] = useState(false);

  const mypitch = localStorage.getItem('pitch');
  // console.log(mypitch)

  useEffect(() => {
    
    setResp(mypitch);

  },[])

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      padding: 74,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      padding: 74,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const responsiveSmall = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      padding: 74,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      padding: 74,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const configuration = new Configuration({
    apiKey: "sk-proj-KXQVpqThKk8h1vqV45fcT3BlbkFJqNV2eTTMGTOOjYPiuIs9",
  });
  
  const openai = new OpenAIApi(configuration);

  const formNew = sessionStorage.getItem('formData')

  const PowerPointGenerator = ({ data }) => {
    const generatePowerPoint1 = (response) => {
      setDownloading(true)
      let pptx = new PptxGenJS();
  
      const contentParts = splitContent(response);
      const startupBackground = { path: '/p1.jpg', w: '100%', h: '100%' };

      contentParts.forEach((part, index) => {
        let slide = pptx.addSlide();
        slide.background = startupBackground;
        slide.addText(`Slide ${index + 1}`, {
          x: 0.5, y: 0.25, w: '100%', h: 1,
          fontSize: 24, bold: true, color: 'FFFFFF', // White text
          align: 'center'
        });
        slide.addText(part, {
          x: 1, y: 1.5, w: 8, h: 5,
          fontSize: 18, color: 'FFFFFF', // White text
          valign: 'middle', align: 'left'
        });
      })
      pptx.writeFile({ fileName: "Generated-Presentation-d1.pptx" });
      setDownloading(false)
    };

    const generatePowerPoint2 = (response) => {
      setDownloading(true)
      let pptx = new PptxGenJS();
      const InovativeBackground = { path: '/p2.jpg', w: '100%', h: '100%' };
      const contentParts = splitContent(response);
  
      contentParts.forEach((part, index) => {
        let slide = pptx.addSlide();
        slide.background = InovativeBackground;
        slide.addText(`Slide ${index + 1}`, {
          x: 0.5, y: 0.25, w: '100%', h: 1,
          fontSize: 24, bold: true, color: 'black', // White text
          align: 'center'
        });
        slide.addText(part, {
          x: 1, y: 1.5, w: 8, h: 5,
          fontSize: 18, color: 'black', // White text
          valign: 'middle', align: 'left'
        });
      })
  
      pptx.writeFile({ fileName: "Generated-Presentation-d2.pptx" });
      setDownloading(false)
    };


    const generatePowerPoint3 = (response) => {
      setDownloading(true)
      let pptx = new PptxGenJS();
      const corporateBackground = { path: 'p3.jpg', w: '100%', h: '100%' };

      const contentParts = splitContent(response);
  
      contentParts.forEach((part, index) => {
        let slide = pptx.addSlide();
        slide.background = corporateBackground;
        slide.addText(`Slide ${index + 1}`, {
          x: 0.5, y: 0.25, w: '100%', h: 1,
          fontSize: 24, bold: true, color: 'FFFFFF', // White text
          align: 'center'
        });
        slide.addText(part, {
          x: 1, y: 1.5, w: 8, h: 5,
          fontSize: 18, color: 'FFFFFF', // White text
          valign: 'middle', align: 'left'
        });
      });
  
      pptx.writeFile({ fileName: "Generated-Presentation-d3.pptx" });
      setDownloading(false)
    };

    const generatePowerPoint4 = (response) => {
      setDownloading(true)
      let pptx = new PptxGenJS();
  
      const financialBackground = { path: 'p4.jpg', w: '100%', h: '100%' };
      
      const contentParts = splitContent(response);
  
      contentParts.forEach((part, index) => {
        let slide = pptx.addSlide();
        slide.background = financialBackground;
        slide.addText(`Slide ${index + 1}`, {
          x: 0.5, y: 0.25, w: '100%', h: 1,
          fontSize: 24, bold: true, color: 'FFFFFF', // White text
          align: 'center'
        });
        slide.addText(part, {
          x: 1, y: 1.5, w: 8, h: 5,
          fontSize: 18, color: 'FFFFFF', // White text
          valign: 'middle', align: 'left'
        });
      });
  
      pptx.writeFile({ fileName: "Generated-Presentation-d4.pptx" });
      setDownloading(false)
    };
  
    // Function to split content into slides based on headings
    const splitContent = (content) => {
      const lines = content.split('\n').filter(line => line.trim() !== '');
      const slides = [];
      let currentSlide = [];
      lines.forEach(line => {
        if (line.startsWith('Slide')) {
          if (currentSlide.length > 0) {
            slides.push(currentSlide.join('\n'));
            currentSlide = [];
          }
        }
        currentSlide.push(line);
      });
      if (currentSlide.length > 0) {
        slides.push(currentSlide.join('\n'));
      }
      return slides;
    };
  
    const PresentationEditor = () => {
      const [slides, setSlides] = useState([]);
      const [selectedStyle, setSelectedStyle] = useState('1');
  
      // Function to handle text change in slides
      const handleContentChange = (index, newContent) => {
        const updatedSlides = slides.map((content, i) => {
          if (i === index) {
            return newContent;
          }
          return content;
        });
        setSlides(updatedSlides);
      };

      // Function to generate PowerPoint based on selected style
    const generateSelectedPowerPoint = () => {
      const response = slides.join('\n');
      switch (selectedStyle) {
        case '1':
          generatePowerPoint1(response);
          break;
        case '2':
          generatePowerPoint2(response);
          break;
        case '3':
          generatePowerPoint3(response);
          break;
        case '4':
          generatePowerPoint4(response);
          break;
        default:
          console.error('Invalid style selected');
      }
    };

    // Load initial slide content
    useEffect(() => {
      setSlides(splitContent(data));
    }, [data]);

   
    
    const slideStyles = {
      1: {
        backgroundColor: '#D6E3F3', // Example background color
        color: 'white', // Example text color
        backgroundImage: 'url(/p1.jpg)',
        backgroundSize: 'cover', // Cover the entire area
        backgroundPosition: 'center', // Center the image
        backgroundRepeat: 'no-repeat', // Do not repeat the image
        
      },
      2: {
        backgroundColor: 'black', 
        color: 'black', 
        backgroundImage: 'url(/p2.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      },
      3: {
        backgroundColor: '#FFFFFF', 
        color: 'white', 
        backgroundImage: 'url(/p3.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      },
      4: {
        backgroundColor: '#FFFFFF', 
        color: 'white', 
        backgroundImage: 'url(/p4.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      },
      // Add more styles as needed...
    };
    
    
    return downloading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    ) :(
      <div>
        <div style={{textAlign:"center", marginTop:"0rem"}}>
        
                    {/* <label>Theme</label> */}
  <select style={{color:"black", fontSize:"15px", fontSize:"20px", border:"1px solid transparent", marginBottom:"1rem"}} value={selectedStyle} onChange={(e) => setSelectedStyle(e.target.value)}>
            <option value="1" style={{}}>Select Templates</option>
            <option value="2">Innovative Startup</option>
            <option value="3">Financial Elegance</option>
            <option value="4">Another Style</option>
          </select>
          <div style={{display:"flex", flexDirection:"row", marginTop:"1rem", justifyContent:"center", alignItems:"center"}}>
            <button onClick={generateSelectedPowerPoint} 
             style={{
              display: 'flex', // Use flexbox for alignment
              alignItems: 'center', // Center items vertically
              justifyContent: 'center', // Center items horizontally
              color: "white",
              cursor: "pointer",
              background: "#0262DF",
              marginRight: "0.5rem",
              padding: "0.5rem",
              paddingLeft: "1.5rem",
              paddingRight: "1.5rem",
              borderRadius: "8px",
              borderColor: "#0262DF",
              borderStyle: 'solid' // Make sure to set the border style
            }}
            >
            <CheckIcon style={{ marginRight: '0.5rem', color:"white",fontSize:"17px" }} /> Confirm
            </button>
            
            <button 
  onClick={()=>setSelectedStyle("1")} 
  style={{
    display: 'flex', // Use flexbox for alignment
    alignItems: 'center', // Center items vertically
    justifyContent: 'center', // Center items horizontally
    color: "#0262DF",
    cursor: "pointer",
    background: "transparent",
    marginRight: "0.5rem",
    padding: "0.5rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    borderRadius: "8px",
    border: "1px solid #0262DF",
  }}
>
  <ReplayIcon style={{ marginRight: '0.5rem', color:"#0262DF",fontSize:"17px" }} /> Undo {/* Add margin to the icon */}
</button>

<button 
  style={{
    display: 'flex', // Use flexbox for alignment
    alignItems: 'center', // Center items vertically
    justifyContent: 'center', // Center items horizontally
    color: "#0262DF",
    cursor: "pointer",
    background: "transparent",
    padding: "0.5rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    borderRadius: "8px",
    border: "1px solid #0262DF",
  }}
  onClick={generateSelectedPowerPoint}
>
  <FileDownloadIcon style={{ marginRight: '0.5rem', color:"#0262DF",fontSize:"17px" }} /> Download {/* Add margin to the icon */}
</button>

          </div>

        </div>
        {/* {slides.map((content, index) => (
<SlidePreview
            key={index}
            slideContent={content}
            onContentChange={handleContentChange}
            index={index}
            style={selectedStyle}
          />        ))} */}

<div style={{padding:"3rem"}}>
<Carousel
  showDots={false}
  responsive={responsive}
  autoPlay={false}
  autoPlaySpeed={2000}
  transitionDuration={500}
  infinite={true}
  swipeable={false}
  
  style={{ maxWidth: '600px', margin: 'auto'}} // Centering the carousel
>
  {slides.map((slideContent, index) => (
    <div 
      style={{ 
        ...slideStyles[selectedStyle], // Apply the selected style
        border: '1px solid gray', 
        maxWidth: '850px', // Smaller width for each item
        margin: 'auto', // Center the item in the carousel
  
        padding: '10px',
        borderRadius: '10px', // Rounded corners
        minHeight: '250px', // Minimum height
        textAlign:"center"
      }}
    >
      <h3 style={{ color: slideStyles[selectedStyle].color, fontSize:"22px"}}>Slide {index + 1}</h3>
      <textarea
        style={{ 
          width: '100%', 
          height: '350px',
          fontSize:"18px", 
          backgroundColor: 'transparent', 
          color: slideStyles[selectedStyle].color, 
         
          border: 'none', 
          padding: '10px' 
        }}
        value={slideContent}
        onChange={(e) => handleContentChange(index, e.target.value)}
      />
    </div>
  ))}
</Carousel>

{/* 
<Carousel
  showDots={false}
  responsive={responsiveSmall}
  autoPlay={false}
  autoPlaySpeed={2000}
  transitionDuration={500}
  infinite={true}
  style={{ maxWidth: '600px', margin: 'auto' }} // Centering the carousel
  swipeable={false}
>
  {slides.map((slideContent, index) => (
    <div 
      style={{ 
        ...slideStyles[selectedStyle], // Apply the selected style
        border: '1px solid gray', 
        margin: '10px', 
        padding: '10px',
        borderRadius: '10px', // Rounded corners
        minHeight: '300px', // Minimum height
      }}
    >
      <h3 style={{ color: slideStyles[selectedStyle].color, fontSize:"22px" }}>Slide {index + 1}</h3>
      <textarea
        style={{ 
          width: '100%', 
          height: '280px',
          fontSize:"18px", 
          backgroundColor: 'transparent', 
          color: slideStyles[selectedStyle].color, 
         
          border: 'none', 
          padding: '10px' 
        }}
        value={slideContent}
        onChange={(e) => handleContentChange(index, e.target.value)}
      />
    </div>
  ))}
</Carousel> */}
</div>
        {/* <button onClick={generateSelectedPowerPoint}>Generate PowerPoint</button> */}
      </div>
    );
  };

  return (
    <PresentationEditor />
  );
};
    

  

  const handleDownload = (text) => {
    // Create a new Blob with the text content
    const blob = new Blob([text], { type: 'text/plain' });
    // Create a temporary anchor element
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = 'file.doc';
    // Simulate a click on the anchor to trigger the download
    downloadLink.click();
  };

  const handleSearch = () => {
    if(query.trim()=="" || query.trim()==null || query.trim()==undefined) {
      alert("Please enter a search term")
      return;
    }
    setLoading(true);
    const bingApiUrl = 'https://api.bing.microsoft.com/v7.0/search';
    const apiKey = 'fc6422b92ec6420aadeeb80c22ca7b76'; // Replace with your API key

    fetch(bingApiUrl + '?q=' + encodeURIComponent(query), {
        method: 'GET',
        headers: {
            'Ocp-Apim-Subscription-Key': apiKey
        }
    })
    .then(response => response.json())
    .then(data => {
        // setResults(data.webPages ? data.webPages.value : []);
        const snippets = data.webPages ? data.webPages.value.map(item => item.snippet) : [];
        setResults(snippets)
    })
    .catch(error => {
        console.error('Error fetching data: ', error);
    });
};


useEffect(() => {
    if(results?.length > 0) {
        sendBotMessage()
    }
  }, [results]);

  const sendBotMessage = async (userMessage) => {
    setLoading(true)
    const message = `Please generate Pitch Deck for this company data: "${formNew}". Check and filter results from this live data fetched from bing: ${results} and respond with desired results And if query is not about related to bing then user your own logic. If query contains or ask about live search internet or browse live then just check my fetched data from bing that I provided and In the response never mention data is from bing. Just write output. Plz only make slides starting with slide number 1 & so on without any braces or bracket or quotation mark in start just write text start with slide as my application slide generation function work on this only`;

    try {
      const result = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        temperature: 0.75,
        messages: [{ role: "user", content: message }],
      });


      const botMessage = {
        content: result?.data?.choices[0]?.message?.content,
        isUser: false,
      };

      setResp(result?.data?.choices[0]?.message?.content)
      localStorage.setItem('pitch', result?.data?.choices[0]?.message?.content)
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)

    }
  };


  const pitch = localStorage.getItem('pitch')
  console.log(pitch)


  const handleNext = () => {
    // Save to sessionStorage before moving to the next step
    sessionStorage.setItem('formData', JSON.stringify({ ...formData, step: activeStep + 1 }));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  console.log(formData)

  // Render the specific content for the current step
  const renderStepContent = (step) => {
    switch (step) {
        case 0:
            return (
              <div style={{ textAlign: "center" }}>
                <h1 style={{ fontSize: "35px", fontWeight: "500" }}>Company Name</h1>
                <p style={{ fontSize: "20px" }}>Please enter the name of your company.</p>
                <TextField label="Company Name" fullWidth multiline rows={10} onChange={handleChange} name="companyName" value={formData.companyName || ''} />
              </div>
            );
          case 1:
            return (
              <div style={{ textAlign: "center" }}>
                <h1 style={{ fontSize: "35px", fontWeight: "500" }}>Description of Company</h1>
                <p style={{ fontSize: "20px" }}>Describe your company in detail.</p>
                <TextField label="Company Description" fullWidth multiline rows={10} onChange={handleChange} name="companyDescription" value={formData.companyDescription || ''} />
              </div>
            );
            case 2:
                return (
                  <div style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: "35px", fontWeight: "500" }}>Current Market Problems</h1>
                    <p style={{ fontSize: "20px" }}>What are the current problems in the market that your company addresses?</p>
                    <TextField label="Current Market Problems" fullWidth multiline rows={10} onChange={handleChange} name="marketProblems" value={formData.marketProblems || ''} />
                  </div>
                );
              case 3:
                return (
                  <div style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: "35px", fontWeight: "500" }}>Solution to Problems</h1>
                    <p style={{ fontSize: "20px" }}>What solutions does your company provide to address these problems?</p>
                    <TextField label="Solution to Problems" fullWidth multiline rows={10} onChange={handleChange} name="solutions" value={formData.solutions || ''} />
                  </div>
                );
              case 4:
                return (
                  <div style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: "35px", fontWeight: "500" }}>Solution Features</h1>
                    <p style={{ fontSize: "20px" }}>List the features of your solution.</p>
                    <TextField label="Solution Features" fullWidth multiline rows={10} onChange={handleChange} name="features" value={formData.features || ''} />
                  </div>
                );
              case 5:
                return (
                  <div style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: "35px", fontWeight: "500" }}>Market Size</h1>
                    <p style={{ fontSize: "20px" }}>Estimate the size of the market (TAM, SAM, & SOM).</p>
                    <TextField label="Market Size" fullWidth multiline rows={10} onChange={handleChange} name="marketSize" value={formData.marketSize || ''} />
                  </div>
                );
              case 6:
                return (
                  <div style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: "35px", fontWeight: "500" }}>Business Model</h1>
                    <p style={{ fontSize: "20px" }}>Describe your business model and how you expect to generate revenue.</p>
                    <TextField label="Business Model" fullWidth multiline rows={10} onChange={handleChange} name="businessModel" value={formData.businessModel || ''} />
                  </div>
                );
              case 7:
                return (
                  <div style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: "35px", fontWeight: "500" }}>Go-to-Market Strategy</h1>
                    <p style={{ fontSize: "20px" }}>What is your go-to-market strategy?</p>
                    <TextField label="Go-to-Market Strategy" fullWidth multiline rows={10} onChange={handleChange} name="marketStrategy" value={formData.marketStrategy || ''} />
                  </div>
                );
              case 8:
                return (
                  <div style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: "35px", fontWeight: "500" }}>Competition</h1>
                    <p style={{ fontSize: "20px" }}>Does your company have competition? If yes, who are they?</p>
                    <TextField label="Competition" fullWidth multiline rows={10} onChange={handleChange} name="competition" value={formData.competition || ''} />
                  </div>
                );
              case 9:
                return (
                  <div style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: "35px", fontWeight: "500" }}>Competitive Advantage</h1>
                    <p style={{ fontSize: "20px" }}>What is your company's competitive advantage?</p>
                    <TextField label="Competitive Advantage" fullWidth multiline rows={10} onChange={handleChange} name="competitiveAdvantage" value={formData.competitiveAdvantage || ''} />
                  </div>
                );
              case 10:
                return (
                  <div style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: "35px", fontWeight: "500" }}>Company Traction</h1>
                    <p style={{ fontSize: "20px" }}>Does your company have any traction? Please provide details.</p>
                    <TextField label="Company Traction" fullWidth multiline rows={10} onChange={handleChange} name="traction" value={formData.traction || ''} />
                  </div>
                ); 
                case 11:
                    return (
                      <div style={{ textAlign: "center" }}>
                        <h1 style={{ fontSize: "35px", fontWeight: "500" }}>Funding Needs</h1>
                        <p style={{ fontSize: "20px" }}>Do they have prior funding or need to raise money?</p>
                        <TextField label="Funding needs" fullWidth multiline rows={10} onChange={handleChange} name="fundingNeeds" value={formData.fundingNeeds || ''} />
                      </div>
                     )   
                case 12: 
                   return (
                    <div style={{ textAlign: "center" }}>
                        <h1 style={{ fontSize: "35px", fontWeight: "500" }}>Funding Usage</h1>
                        <p style={{ fontSize: "20px" }}>What are they going to use the funds for?</p>
                        <TextField label="Funds Usage" fullWidth multiline rows={10} onChange={handleChange} name="fundingUsage" value={formData.fundingUsage || ''} />
                      </div>
                   )
      default:
        return 'Unknown Step';
    }
  };
  const scrollableTextAreaStyle = {
    '& .MuiInputBase-inputMultiline': {
      maxHeight: '300px', // Set the maximum height you want
      overflow: 'auto', // This will add a scrollbar when the content exceeds the max height
    },
  };
  

  return loading?
  <div style={{display:"flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection:"column"}}
  >
<Loader/>
  </div>: (

    <div>
    {resp?.length>0?
    <div>
<PowerPointGenerator  data={resp} />

 
    </div>
    : 
    <Container sx={{ mt: 10 }}>
      <h1>Please generate your pitch</h1>
      <a href='/pitchquestions'>Click here</a>
    </Container>}

   <br/>
   <br/>
    </div>
  );
}
