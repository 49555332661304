import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Configuration, OpenAIApi } from "openai";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import backend from "../../../api";
import { SET_RESPONSE_TEXT, setEditorText } from "../../../redux-store/auth/actions";

import { useParams } from "react-router-dom";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import SendIcon from '@mui/icons-material/Send';
import { Grid } from "@mui/material";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import NorthIcon from '@mui/icons-material/North';
import { setChatHistoryId } from "../../../redux-store/auth/actions";

const ChatContainer = styled.div`
  width: 100%;
  height: fit-content;
  
  overflow: hidden; 
  @media (min-width:1400px) {
    
    width:80%;
}
 
`;

const ChatHeader = styled.div`
  padding: 16px;
  background-color: rgb(207 32 49);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
`;

const ChatContent = styled.div`
  height: 74vh;
  padding: 16px;
  margin-top:0.7rem;
  overflow-y: scroll;
  white-space: nowrap;
  /* Hide vertical scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

// Inside your component's render/return:
<ChatContent>
  {/* Your chat messages */}
</ChatContent>

const ChatMessage = styled.div`
  display: flex;
  // justify-content: ${(props) => (props.isUser ? "flex-end" : "flex-start")};
  margin-bottom: -1.5rem;
  padding-Left:0rem;

  
`;

const ChatBubble = styled.div`
  max-width: 100%;
  // padding: 8px;
  border-radius: 8px;
  color: black;
  
`;

const ChatInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid rgba(206, 205, 205, 1);
  margin:1rem;


  `;

const ChatInput = styled.textarea`
  flex: 1;
  min-height: 20px; /* Decreased minimum height */
  max-height: 100px; /* Maximum height before scrolling */
  height: ${props => (props.rows ? `${props.rows * 14}px` : '25px')}; /* Start with a smaller height but allow expansion */
  border: none;
  border-radius: 20px;
  resize: none; /* Prevent resizing by the user */

  /* Additional styling */
  padding: 8px;
  font-size: 16px;
  line-height: 1.5;
  outline: none;

  display:flex;
  align-items: center;
  justify-content: center;
`;

const ChatButton = styled.button`
  margin-left: 16px;
  padding: 8px 16px;
  background-color: rgb(9 70 122);
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border-color: rgb(255 185 0);
`;

const containerStyle = {
  textAlign: 'center',
  padding: '20px',
  margin: '0 auto',
  maxWidth: '800px', // Maximum width of the container
  border: '1px solid #0262DF', // A border to make it stand out, use your color choice
  borderRadius: '10px', // Rounded corners
  backgroundColor: '#f3f4f6', // A light background color
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // A subtle shadow to make it "pop" a bit
  fontSize: '1rem', // Base font size
  lineHeight: '1.6', // Line height for better readability
};

const paragraphStyle = {
  color: '#333', // Darker text color for contrast
  marginBottom: '1rem', // Spacing between paragraphs
};

const emojiStyle = {
  fontSize: '1.2rem', // Larger size for the rocket emoji
};

const styles = {
  fontFamily: '"Manrope", sans-serif',
  fontSize: '16px',

  paddingLeft: "1rem",
  paddingRight: "1rem",
  color: 'white',
  whiteSpace: 'pre-wrap', // allow wrapping of long lines
  wordWrap: 'break-word' // break long words
};

function ChatBox({setopenUpgradePopup}) {

  const [messages, setMessages] = useState([
  ]);
  const [inputText, setInputText] = useState("");
  const [displayText, setDisplayText] = useState("");
  const navigateTo = useNavigate();
  const [typing, setTyping] = useState(false);
  const [response, setResponse] = useState('');
  const [Msgstate, setMsgState] = useState(false);
  const lastItem = messages[messages.length - 1];
  const [loading, setLoading] = useState(false);
  const [creatingNewChat, setCreatingNewChat] = useState(false);

  const lastMessage = "Plz regenerate this answer" + (lastItem?.content || "");

  const [copiedTextForEditor, setCopiedText] = useState('');
  const [textareaRows, setTextareaRows] = useState(3);

  const dispatch = useDispatch()

  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const { RouteId1, RouteId2 } = useParams();
  //  console.log(RouteId1)

  //  const chatId = RouteId2?.id;
  //  const chatId = localStorage.getItem("chatId")

  const promptText = useSelector((state) => state.promptText.promptText);
  const responseText = useSelector((state) => state.responseText.responseText);
  const chatId = useSelector((state) => state.chatId.chatId);


  useEffect(() => {
    if (responseText) {
      sendBotMessage(responseText)
      // AddToEditor()
    }
  }, [responseText]);



  const text =
    "  Greetings! Nice to meet you in the digital world. I'm here to give you a virtual hand and address any questions or concerns you may have. My knowledge and skills are at your disposal. So don't hesitate to ask me something. How can I be of service to you today?";


  const { user } = useSelector((state) => state.auth);

  const [open, setOpen] = React.useState(false);
  const handleOpenAuth = () => { setOpen(true) };

  const handleCloseAuth = () => {
    setOpen(false);
  };

  useEffect(() => {

    if (!user) {
      setOpen(true)
    }

    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      setDisplayText((prevText) => prevText + text[currentIndex]);
      currentIndex++;
      if (currentIndex === text.length) {
        clearInterval(typingInterval);
      }
    }, 10);

    return () => {
      clearInterval(typingInterval);
    };
  }, []);


  const chatContentRef = useRef(null);

  //   const Copyprompt = localStorage.getItem('Copyprompt');
  //   useEffect(() => {
  //     if(Copyprompt) {
  //       setMessages([
  //         {
  //           content: Copyprompt,
  //           isUser: true
  //         }
  //       ])
  //       sendBotMessage(Copyprompt)
  //       localStorage.removeItem('Copyprompt')
  //     }
  //   }, []);


  const copyResponse = (msg) => {
    navigator.clipboard.writeText(msg);
    toast.success("Copied successfully")
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
  };

  const handleInputChange = (event) => {
    if(user.subscription === 'free'){
      setopenUpgradePopup(true);
      return;
    }
    const lines = event.target.value.split('\n').length
    setTextareaRows(lines);
    setInputText(event.target.value);
  };




  const inputRef = useRef();

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {

    if (inputText.trim() !== "") {

      setTyping(true)

      const newMessage = {
        content: inputText.trim(),
        isUser: true,
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInputText("");
      sendBotMessage(newMessage.content);


    } else {
      alert("Please enter a message")
    }
  };



  const [levelList, setlevelList] = useState("Portuguese");
  function handleSelectChange(event) {
    setlevelList(event.target.value);
  }




  const [workflows, setWorkflows] = useState([]);
  const email = user.email;

  const [userData, setUserData] = useState();



  const Copyprompt = localStorage.getItem('Copiedprompt');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchUserID = async () => {
          const res = await backend.get(`/user?email=${email}`);
          await setUserData(res?.data);
          setWorkflows(res?.data?.workflows);
        };
        await fetchUserID();
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   if (userData && Copyprompt && userData.apiKey) {
  //     setMessages([
  //       {
  //         content: Copyprompt,
  //         isUser: true,
  //       },
  //     ]);

  //     console.log(userData);
  //     sendBotMessage(Copyprompt);
  //     localStorage.removeItem('Copiedprompt');
  //   }
  // }, [userData, Copyprompt]);

  // useEffect(() => {
  //   if (Copyprompt !==undefined || Copyprompt !==null) {
  //     setInputText(Copyprompt);
  //   }
  // }, [Copyprompt]);

  // useEffect(() => {
  //   if (userData && promptText && userData.apiKey) {

  //     const userMessage = {
  //       content: promptText,
  //       isUser: true,
  //     };

  //     setMessages((prevMessages) => [...prevMessages, userMessage]);

  //     console.log(userData);
  //     sendBotMessage(promptText);
  //     // localStorage.removeItem('Copiedprompt');
  //     dispatch(setPromptText(null));

  //   }
  // }, [userData, promptText]);

  useEffect(() => {
    if (promptText) {
      setInputText(promptText);
    }
  }, [promptText]);

  useEffect(() => {
    if (chatId) {
      navigateTo(`/chatbot/${chatId}`)
    }
  }, [chatId])

  // console.log(userData)

  const pitch = localStorage.getItem('pitch');
  console.log(pitch)

  const sendBotMessage = async (userMessage) => {
    setTyping(true)
    const message = `Please respond normally and by also considering your previous response and user selected pitch in context if not empty: Response: "${response}". Pitch is:"${pitch}" Here is my query: "${userMessage}".`;

    const configuration = new Configuration({
      apiKey: "sk-proj-KXQVpqThKk8h1vqV45fcT3BlbkFJqNV2eTTMGTOOjYPiuIs9",
      // apiKey: userData?.apiKey
    });
    const openai = new OpenAIApi(configuration);

    const result = await openai.createChatCompletion({
      model: "gpt-4",
      temperature: 0.5,
      messages: [{ role: "user", content: message }],
    });

    setResponse(result?.data?.choices[0]?.message?.content)

    const botMessage = {
      content: result?.data?.choices[0]?.message?.content,
      isUser: false,
    };
    setMessages((prevMessages) => [...prevMessages, botMessage]);
    const dataMsg = result?.data?.choices[0]?.message?.content
    localStorage.setItem('botMessage', dataMsg);
    setTyping(false)

  };


  // useEffect(() => {
  //   const getsChat = async () => {
  //     const userId = user?._id
  //     try {
  //       // setLoading(true);
  //       const response = await backend.put(`/chats/${chatId}`);
  //         // setLoading(false);
  //         setMessages(response?.data?.data);

  //     } catch (error) {
  //       console.error(error);
  //       // toast.error("Error saving story");
  //     }
  //   };

  //   getsChat();
  // },[]);


  console.log(chatId);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // First try-catch block
        // setLoading(true);
        const response = await backend.put(`/chats/${chatId}`); // Assuming backend is set up and axios is your HTTP client library
        // setLoading(false);
        console.log(response?.data);
        setMessages(response?.data?.data?.chat);
        setMsgState(true);
      } catch (error) {
        console.error(error);
        // toast.error("Error saving story");
      }
    };

    fetchData();
    // Call the fetchData function to execute the API call when the component mounts
  }, [chatId]);

  useEffect(() => {
    const saveChat = async () => {
      const userId = user?._id;

      try {
        // Second try-catch block
        const body = {
          UserId: userId,
          chat: messages,
        };
        console.log(chatId);

        // setLoading(true);
        const response = await backend.put(`/chats/${chatId}`, body);
        if (response) {
          // setLoading(false);
          console.log(response);
        }
      } catch (error) {
        console.error(error);
        // toast.error("Error saving story");
      }
    };

    const createNewChat = async () => {
      const userId = user?._id;

      try {
        // Second try-catch block
        const body = {
          UserId: userId,
          chat: messages,
        };


        // setLoading(true);
        const response = await backend.post(`/chats`, body);
        if (response) {
          // setLoading(false);
          console.log(response)
          const RouteID = response?.data?.data?._id
          // localStorage.setItem("chatId", RouteID)
          if (RouteID) {

            await dispatch(setChatHistoryId(RouteID))
            // await UpdateProjectById(RouteID)
            // navigateTo(`/chatbot/${RouteID}`)
          }
        }
      } catch (error) {
        console.error(error);
        // toast.error("Error saving story");
      }
    };

    if (response !== '') {
      if (chatId) { saveChat(); }
      else {
        createNewChat()
      }
    }


  }, [response]);




  const AddToEditor = (msg) => {
    dispatch(setEditorText(msg));
  }


  const {
    transcript,
    listening,
    resetTranscript,
    finalTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  useEffect(() => {
    if (finalTranscript) { // Check if listening is off and transcript is fully completed
      setInputText(finalTranscript)
      resetTranscript();
    }
  }, [finalTranscript]); // Add finalTranscript and transcript to the dependency array

  console.log(finalTranscript);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const saveChat = async () => {
    const userId = user._id
    setLoading(true)
    try {
      const body = {
        UserId: userId,
        projectId: "test",
        chat: messages,
      };

      const response = await backend.post("/chats", body);
      // setLoading(false);
      console.log(response)
      const RouteID = response?.data?.data?._id
      // localStorage.setItem("chatId", RouteID)
      if (RouteID) {

        await dispatch(setChatHistoryId(RouteID))
        // await UpdateProjectById(RouteID)
        // navigateTo(`/chatbot/${RouteID}`)
      }

      setLoading(false)

    } catch (error) {
      console.error(error);
      setLoading(false)

      // toast.error("Error saving story");
    }

  };

  // console.log(transcript)
  const styles = {
    fontFamily: '"Manrope", sans-serif',
    fontSize: '16px',
    lineHeight: '1.5',
    color: 'black',

    whiteSpace: 'pre-wrap', // allow wrapping of long lines
    wordWrap: 'break-word' // break long words
  };

  return (
    <ChatContainer >

      {/* <ChatHeader><img className="headerLogo" src="/logo-small.png"/></ChatHeader> */}

      <ChatContent ref={chatContentRef} >
        {messages.length === 0 ?
          <div>
            <ChatMessage key={0} isUser={false} >
              <ChatBubble>
                <div style={{ display: "flex", alignItems: "flex-start" }}> {/* Adjusted alignment to flex-start */}
                  {/* <p style={{
                    background: "#8E8E8E",
                    marginRight: "1rem",
                    textAlign: "center",
                    width: "30px",
                    height: "30px",
                    fontWeight: "600",
                    lineHeight: "30px",
                    borderRadius: "50%",
                    color: "white",
                    cursor: "pointer",
                  }}>AI</p> */}
                  <img src="/icons/chat1.png" style={{ marginRight: '1rem', marginTop: '1rem', height: '25px' }} />
                  <pre style={{
                    ...styles,
                    flex: "1", // Allow the content area to expand
                    minWidth: "0", // Prevent the content from causing element collapse
                    paddingTop: "0.22rem",
                    marginTop: '8px',
                  }}>
                  <h5 style={{ margin: '0', fontWeight: '700' }} >Chatbot</h5>
                    Welcome to our AI Business Chatbot, your personalized guide to success in the business world! 🚀
                  </pre>
                </div>


              </ChatBubble>
            </ChatMessage>
            <br />
            <ChatMessage key={1} isUser={false}>
              <ChatBubble>
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  {/* <p style={{
                    background: "#8E8E8E",
                    marginRight: "1rem",
                    textAlign: "center",
                    width: "30px",
                    height: "30px",
                    fontWeight: "600",
                    lineHeight: "30px",
                    borderRadius: "50%",
                    color: "white",
                    cursor: "pointer",
                  }}>AI</p> */}
                  <img src="/icons/chat1.png" style={{ marginRight: '1rem', marginTop: '1rem', height: '25px' }} />
                  <pre style={{
                    ...styles,
                    flex: "1", // Allow the content area to expand
                    minWidth: "0", // Prevent the content from causing element collapse
                    paddingTop: "0.22rem",
                    marginTop:'8px',
                  }}>
                    <h5 style={{ margin: '0', fontWeight: '700' }} >Chatbot</h5>
                    Ask questions about your business strategy, seek advice on investor pitches, or explore the intricacies of navigating the dynamic landscape of entrepreneurship. Our AI is well-versed in the language of venture capital, ready to provide you with valuable perspectives and actionable recommendations.  </pre>
                </div>

              </ChatBubble>
            </ChatMessage>
          </div>
          : null}

        {messages?.map((message, index) => (
          <div key={index}>

            {!message.isUser ? (
              <div key={index}>
                <ChatMessage key={index} isUser={message.isUser}>
                  {message.content ?
                    <ChatBubble>
                      <div style={{ display: "flex", alignItems: "flex-start" }}>
                        {/* <p style={{
                          background: "#8E8E8E",
                          marginRight: "1rem",
                          textAlign: "center",
                          width: "30px",
                          height: "30px",
                          fontWeight: "600",
                          lineHeight: "30px",
                          borderRadius: "50%",
                          color: "white",
                          cursor: "pointer",
                        }}>AI</p> */}
                        <img src="/icons/chat1.png" style={{ marginRight: '1rem', marginTop: '1rem', height: '25px' }} />
                        <pre style={{
                          ...styles,
                          flex: "1", // Allow the content area to expand
                          minWidth: "0", // Prevent the content from causing element collapse
                          paddingTop: "0.22rem",
                          marginTop: '8px',
                        }}>
                        <h5 style={{ margin: '0', fontWeight: '700' }} >Chatbot</h5>
                          {message.content}
                        </pre>
                      </div>

                    </ChatBubble> : null}
                </ChatMessage>

              </div>
            ) : (
              <ChatMessage key={index} isUser={message.isUser}>

                <ChatBubble style={{ background: "white" }}>
                  <div style={{ display: "flex", alignItems: "flex-start" }}>
                    {/* <p style={{
                      background: "#0262DF",
                      marginRight: "1rem",
                      textAlign: "center",
                      width: "30px",
                      height: "30px",
                      fontWeight: "600",
                      lineHeight: "30px",
                      borderRadius: "50%",
                      color: "white",
                      cursor: "pointer",
                    }}>B</p> */}
                    <img src="/icons/chat2.png" style={{ marginRight: '1rem', marginTop: '1rem', height: '25px' }} />
                    <pre style={{
                      ...styles,
                      flex: "1", // Allow the content area to expand
                      minWidth: "0", // Prevent the content from causing element collapse
                      paddingTop: "0.22rem",
                      marginTop: '8px',
                    }}>
                      <h5 style={{ margin: '0', fontWeight: '700' }} >You</h5>
                      {message.content}
                    </pre>
                  </div>


                </ChatBubble>


              </ChatMessage>

            )}
            <br />
            {typing && index === messages.length - 1 && (

              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: message.isUser ? "2rem" : "4rem" }}>
                <br /><img src="/typing.gif" style={{ width: '70px', height: '40px', borderRadius: "25px" }} />
              </div>
            )}
          </div>
        ))}

        {/* {messages.map((message, index) => (
      <ChatMessage key={index} isUser={message.isUser}>
      <ChatBubble isUser={message.isUser}>{message.content}</ChatBubble><br/>
      </ChatMessage>
      ))} */}
      </ChatContent>

      {/* <p style={{ justifyContent: 'flex-start',  }}>
      </p>      
      <br/> */}

      <ChatInputContainer>
        <ChatInput
          type="text"
          value={inputText}
          onChange={handleInputChange}
          style={{ paddingLeft: "1rem", fontFamily: '"Manrope", sans-serif' }}
          placeholder={ width < 1550 ? "Message business chatbot..." : "Ask questions regarding your pitch deck and business model...." }
          onKeyPress={handleKeyPress}
          ref={inputRef}
          disabled={user.subscription === 'free'}
        />

        <NorthIcon style={{ marginRight: width<1500?"0.5rem":"2rem" , paddingLeft: width<1500? "0.5rem":"1rem", color: "#0262DF", background: "" }} onClick={handleSendMessage} />

        {/* <ChatButton onClick={handleSendMessage}>Send</ChatButton> */}

        {listening ?
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyItems: "center", }}>
            <button style={{ background: "transparent", borderColor: "transparent", }} onClick={SpeechRecognition.startListening}>  <MicIcon onClick={SpeechRecognition.startListening} style={{ color: "#0262DF", cursor: "pointer", marginTop: "0.2rem" }} /></button>

          </div> :
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyItems: "center" }}>
            <button style={{ background: "transparent", borderColor: "transparent" }} onClick={SpeechRecognition.startListening}>  <MicOffIcon onClick={SpeechRecognition.startListening} style={{ color: "#0262DF", cursor: "pointer", marginTop: "0.2rem" }} /></button>

          </div>}

        {/* <p>Speak: {listening ? 'on' : 'off'}</p> */}
        {/* <button onClick={resetTranscript}>Reset</button> */}

      </ChatInputContainer>

    </ChatContainer>

  );
}

export default ChatBox;        