import React, { useState, useEffect } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import { CircularProgress, Button, Menu, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import backend from "../../api";
import sessionstorage from "sessionstorage";
import { useNavigate } from "react-router-dom"
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import Classes from './PitchTemplates.module.css'
import Popup from "./Popup";
// import sessionstorage from "sessionstorage";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// Swiper Styles
import './styles.css';

// import required modules
import { Pagination } from 'swiper/modules';
import UpgradePopup from "../../Global-Components/UpgradePopup/UpgradePopup";
import MobileVersionPopup from "../../Global-Components/MobileVersionPopup/MobileVersionPopup";
import { setUserData } from "../../redux-store/auth/actions";

function PitchTemplates() {
  const { user } = useSelector((state) => state.auth);
  // console.log(user);
  const [userFiles, setUserFiles] = useState([{
    name: "Ultimate Light",
    uploadedAt: new Date(),
    image: "/u-light.jpg",
    url: "https://pitch-bucket-s3.s3.us-east-2.amazonaws.com/Ultimate+Pitch+Deck+(Light).pptx"
  },
  {
    name: "Ultimate Dark",
    uploadedAt: new Date(),
    image: "/u-dark.jpg",
    url: "https://pitch-bucket-s3.s3.us-east-2.amazonaws.com/Ultimate+Pitch+Deck+(Dark).pptx"
  }]);
  const [loading, setLoading] = useState(false);
  const [editState, setEditState] = useState({});
  const [editing, setEditing] = useState(false);
  const [fileName, setFileName] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [newFileName, setNewFileName] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // For search query
  const [sortOrder, setSortOrder] = useState(""); // For sort order
  const [filteredFiles, setFilteredFiles] = useState([{
    name: "Ultimate light",
    uploadedAt: new Date(),
    image: "/u-light.jpg",
    url: "https://s3-hamza-new.s3.ap-south-1.amazonaws.com/Ultimate+Pitch+Deck+(Light).pptx"
  },
  {
    name: "Ultimate Dark",
    uploadedAt: new Date(),
    image: "/u-dark.jpg",
    url: "https://s3-hamza-new.s3.ap-south-1.amazonaws.com/Ultimate+Pitch+Deck+(Dark).pptx"
  }
  ]); // For sorted/filtered files
  const [Image, setImage] = useState();
  const [url, setUrl] = useState();
  const [open, setOpen] = useState(false);

  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const isSortMenuOpen = Boolean(sortAnchorEl);
  const [openUpgradePopup, setopenUpgradePopup] = useState(false);
  const closeUpgradePopup = () => {
    setopenUpgradePopup(false);
  }
  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    // Filter and sort userFiles whenever searchQuery or sortOrder changes
    let result = [...userFiles];

    if (searchQuery) {
      result = result.filter(file =>
        file.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (sortOrder === "name") {
      result.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortOrder === "date") {
      result.sort((a, b) => new Date(b.uploadedAt) - new Date(a.uploadedAt));
    }

    setFilteredFiles(result);
  }, [searchQuery, sortOrder, userFiles]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortMenuClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setSortAnchorEl(null);
  };

  const handleSortOptionClick = (sortOption) => {
    setSortOrder(sortOption)
    handleSortMenuClose();
  };

  const handleDowloadClick = (image, url) => {
    setOpen(true)
    setUrl(url)
    setImage(image)
  }

  const downloadPdf = () => {
    if (user?.subscription === 'free') {
      console.log('not allowd, free user');
      setopenUpgradePopup(true);
      return;
    }
    if (user?.subscription === 'monthly' || user?.subscription === 'yearly') {
      console.log('allowd, premium user');
      const link = document.createElement('a');
      link.href = 'https://pitch-bucket-s3.s3.us-east-2.amazonaws.com/Ultimate+Pitch+Deck+Template.zip ';
      link.setAttribute('download', '');
      // link.target = '_blank'
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const [userID, setUserID] = useState(user._id);

  const dispatch = useDispatch();
  
  const getUserDetails = async () => {
    const UserToken = user?.token;
    const UserExists = user?.exists;
    try {
      const response = await backend.put(`/user/${userID}`);
      console.log(response)
      console.log(response?.data?.UpdateUser?.files);
      const data = await dispatch(setUserData({
        ...response.data.UpdateUser,
        token: UserToken,
        profileExists: UserExists,
      }));
      console.log(data);

    } catch (error) {
      console.error("Failed to get data from server", error);

    }
  };
  useEffect(() => { getUserDetails(); }, []);

  return loading ?
    <div style={{ background: 'white' }} >
      <div className={Classes.banner} >

      </div>
      <div className={Classes.mainRightBg} >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100vh",
            alignItems: "center",
          }}
        >
          <div id="loading-bar-spinner" class="spinner">
            <div class="spinner-icon" style={{ borderTopColor: '#0262DF', borderLeftColor: '#0262DF' }}></div>
          </div>
        </div>
      </div>
    </div>
    : (
      <>
        <>
          <MobileVersionPopup />
          {openUpgradePopup && <UpgradePopup closeUpgradePopup={closeUpgradePopup} closeable={true} />}
          <div style={{ background: 'white' }} >
            <div className={Classes.banner} >

            </div>
            <div className={Classes.mainRightBg} >
              <div className={Classes.container} >
                <div className={Classes.innerCon} >
                  <h1 className={Classes.title1} >Ultimate pitch deck template</h1>
                  <h5 className={Classes.text1} >Premium slides designed to win pitch contests & secure investor meetings</h5>

                  <div className={Classes.imagesCon} >
                    <div className={Classes.imgShadow} >
                      <img src="/Ultimate-Light-1.jpg" alt="image 1" />
                    </div>
                    <div></div>
                    <div className={Classes.imgShadow} >
                      <img src="/Ultimate-Light-2.jpg" alt="image 1" />
                    </div>
                    <div></div>
                    <div className={Classes.imgShadow} >
                      <img src="/Ultimate-Light-3.jpg" alt="image 1" />
                    </div>
                  </div>

                  <h1 className={Classes.title2} >Create the <span className={Classes.gradientText} >perfect</span> deck!</h1>
                  <h5 className={Classes.text2} >Effortlessly build your winning pitch deck with over 125 premium slides to choose <br /> from and real examples to help guide you.</h5>
                  <div className={Classes.con2} >
                    <div>
                      <h5 className={Classes.lightTest} >Easily edit</h5>
                      <h1 className={Classes.margin140} > <span className={`${Classes.gradientText} ${Classes.numHeading}`} >125+</span> </h1>
                      <h5 className={Classes.text3} >Premium designed slides</h5>
                    </div>
                    <div>
                      <h5 className={Classes.lightTest} >Including</h5>
                      <h1 className={Classes.margin140} > <span className={`${Classes.gradientText} ${Classes.numHeading}`} >50+</span> </h1>
                      <h5 className={Classes.text3} >Real examples</h5>
                    </div>
                    <div>
                      <h5 className={Classes.lightTest} >Based on</h5>
                      <h1 className={Classes.margin140} > <span className={`${Classes.gradientText} ${Classes.numHeading}`} >150+</span> </h1>
                      <h5 className={Classes.text3} >Successful pitch decks</h5>
                    </div>
                  </div>
                  <button className={Classes.downloadBtn} onClick={downloadPdf} >
                    <img src="/download-white.png" alt="Download Icon" />
                    <h5>Download now</h5>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
        {/* Boxes Code */}
        <>
          {/* <div className={Classes.container} >
        <div className={Classes.searchSection} >
          <div className={Classes.searcCon} >
            <svg xmlns="http://www.w3.org/2000/svg" height="18px" width="18px" fill="#838383" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" /></svg>
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
              className={Classes.searchInput}
            />
          </div>
        </div>
        <div className={Classes.main}>
          <ul className={Classes.cards}>
            {filteredFiles?.map((value, index) => (
              <li className={Classes.cards_item}>
                <div className={Classes.card}>
                  <div className={Classes.card_image}><img src={value.image} onClick={() => handleDowloadClick(value.image, value.url)} /></div>
                  <div className={Classes.card_content}>
                    <span >{value.name}</span>
                    <a href={value.url} >
                      <img src="/icons/download.png" alt="DeleteIcon"/>
                    </a>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div> */}
        </>
      </>
    );
}

export default PitchTemplates;


{/* <div className={Classes.slider} >
              <Swiper
                ref={swiperRef}
                initialSlide={1}
                slidesPerView={'auto'}
                loop={true}
                centeredSlides={true}
                spaceBetween={width <= 900 ? 10 : 20}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="myTemplateSwiper"
                allowSlidePrev={false}
                allowSlideNext={false}
                grabCursor={false}
                mousewheel={false}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
              >
                <SwiperSlide>
                  <img className={Classes.img} src="/Ultimate-Light-1.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className={Classes.img} src="/Ultimate-Light-2.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className={Classes.img} src="/Ultimate-Light-3.jpg" />
                </SwiperSlide>
              </Swiper>
            </div> */}