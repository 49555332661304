import React, { useState, useEffect } from "react";
import "./Menu.css";
import Nav from "./Nav";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../redux-store";
import AuthPopup from "./auth-pop/Popup";
import { motion, easeOut } from "framer-motion";


const Menu = () => {
  const { user } = useSelector((state) => state.auth);
  const ProjectID = localStorage.getItem("projectID");

  const [isOpen, setIsOpen] = useState(false);

  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleOpenAuth = () => {
    setOpen(true);
    setIsOpen(false);
  };
  const handleCloseAuth = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const navigateToDashboard = () => {
    navigateTo(`/dashboard/${ProjectID}`);
  };

  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const navigateTo = useNavigate();

  const handleLogout = async () => {
    localStorage.clear();
    navigateTo("/");
    await dispatch(authActions.logout());
    toast.success("Signed out successfully");
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const handleMenuClick = (event) => {
    event.preventDefault();
  }

  const handleClick = (value) => {
    // handleClose();
    // scrollToElement(value);
    navigateTo('/', { state: { data: value } });
  }

  const scrollToElement = (elementId) => {
    const targetElement = document.getElementById(elementId);

    if (targetElement) {
      const offset = 0;
      const targetPosition = targetElement.offsetTop + offset;

      window.scroll({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };

  return width > 938 ? (
    <Nav />
  ) : (
    <div className="menu-wrapper">
      <img className='mobile' src="Blue.png" alt="icon" />
      <div className={`menu-icon ${isOpen ? "open" : ""}`} onClick={handleToggle} >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
        </svg>
      </div>
      <nav className={`menu-navigation ${isOpen ? "open" : ""}`} onMouseDown={(event) => { handleMenuClick(event) }} >
        <div className="crossCon">
          <svg
            className="crossIcon"
            onClick={handleClose}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
          </svg>
        </div>
        <div className="navItemsCon">
          <div>
            <a href="/" > Home </a>
            <a onClick={() => handleClick("how-it-works")} > How it Works </a>
            <a onClick={() => handleClick("features")} > Features </a>
            <a onClick={() => handleClick("pricing")} > Pricing </a>
            <a onClick={() => handleClick("faq")} > Faq </a>
          </div>
          <div>
            <hr className="menuLine" />
            <a href="/privacy-policy" > Privacy Policy </a>
            <a href="/terms-of-service" > Terms of Service </a>
            <a href="/contact-us" > Contact Us </a>
            {user ? (
              <>
                <a onClick={() => navigateToDashboard()} > Dashboard </a>
                <a onClick={handleLogout} > Logout </a>
              </>
            ) : (
              <a href="/login" > Sign In </a>
            )}
          </div>
        </div>
      </nav>
      {isOpen &&
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.3, // Total duration of the animation
            ease: easeOut, // Custom easing curve
            delay: 0 // Delay before animation starts
          }}
        >
          <div className="bgMenu" onClick={handleClose} ></div>
        </motion.div>
      }
      <div>{open && <AuthPopup onClose={handleCloseAuth} />}</div>
    </div>
  );
};

export default Menu;
