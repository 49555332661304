import React from 'react'
import Classes from './SummaryPopup.module.css'
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";

function SummaryPopup({ style, openSummary, handleCloseSummary, deckData }) {

    const handleBgClick = () => {
        handleCloseSummary();
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    return (
        <>
            <div className={Classes.popup} onClick={handleBgClick} >
                <div className={Classes.card} onClick={(event) => { handleCardClick(event) }} >

                    <div className={Classes.close} onClick={handleCloseSummary} >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                    </div>

                    <div className={Classes.flexBetween} >
                        <h2 >Summary</h2>
                    </div>

                    <div className={Classes.flexAlign} >
                        <img
                            src="/icons/slideshow.png"
                            style={{ height: "30px", width: "30px", verticalAlign: "middle" }}
                        />
                        <h3 style={{ margin: 0 }}>Deck structure</h3>
                    </div>
                    <p className={Classes.para} >
                    The deck is well-structured, following a logical flow that guides the viewer through the company overview, problem statement, solution, market validation, business model, and financials. However, some slides are text-heavy which might overwhelm the audience. Simplifying complex slides and using more visuals could improve comprehension and retention.
                    </p>

                    <div className={Classes.flexAlign} >
                        <img
                            src="/icons/globe.png"
                            style={{ height: "28px", width: "28px", verticalAlign: "middle" }}
                        />
                        <h3 style={{ margin: 0 }}>Market potential</h3>
                    </div>
                    <p className={Classes.para} >
                        The deck effectively highlights a substantial market opportunity using credible sources and growth projections. It identifies and segments the market clearly, which shows thorough market understanding. The use of data to justify the market size and growth potential is compelling and persuasive.
                    </p>

                    <div className={Classes.flexAlign} >
                        <img
                            src="/icons/ss-3.png"
                            style={{ height: "28px", width: "28px", verticalAlign: "middle" }}
                        />
                        <h3 style={{ margin: 0 }}>Business model</h3>
                    </div>

                    <p className={Classes.para} >
                        The business model is clearly articulated, with a strong emphasis on scalability and operational details. The financial projections are detailed, showing potential profitability. However, the model depends heavily on market adoption and the successful implementation of a franchising strategy, which introduces some risk.
                    </p>
                    
                </div>
            </div>
        </>
    )
}

export default SummaryPopup
