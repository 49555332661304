import React, { useState, useEffect } from 'react'
import Classes from './styles/style.module.css'

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function UploadPopup({ closeUploadPopup, handleImageClicked, handleFileChange }) {
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [pdfSizeError, setpdfSizeError] = useState(false);

    const handleDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDraggingOver(true);
    };

    const handleDragLeave = () => {
        setIsDraggingOver(false);
    };

    const handleBgClick = () => {
        closeUploadPopup();
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }
    const handleDropBg = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const files = event.dataTransfer.files;
        if (files.length > 1) {
            setpdfSizeError(true);
            setTimeout(() => {
                setpdfSizeError(false);
            }, 5000);
            return;
        }
        handleFileChange(files, 'uploadBrochure');
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDraggingOver(true);
    };

    return (
        <>
            <Snackbar open={pdfSizeError} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert severity="error" sx={{ width: '100%' }}>
                    Please drop only 1 file at a time.
                </Alert>
            </Snackbar>
            <div className={Classes.popup} onClick={handleBgClick} onDrop={handleDropBg} >
                <div className={Classes.card}
                    onClick={(event) => { handleCardClick(event) }}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                >
                    <div className={Classes.close} onClick={closeUploadPopup} >
                        <img src='/icons/close.png' alt='close icon' />
                    </div>
                    <div className={Classes.cardCon}
                        style={{ border: isDraggingOver ? '2px dashed green' : '2px dashed #0262DF' }}
                    >
                        <img className={Classes.imgCloud} src='/icons/uploadCloud.png' alt='upload Icon' />
                        <h5 className={Classes.title} >Drag and drop PDF pitch deck file here</h5>
                        <h5 className={Classes.text1} >or</h5>
                        <button onClick={handleImageClicked}
                        // style={{ backgroundColor: isDraggingOver ? 'green' : '#0262DF' }}
                        >
                            <img className={Classes.chooseFile} src='/icons/chooseFile.png' />
                            <h5>Choose file</h5>
                        </button>
                        <h5 className={Classes.text2} >Accepts PDF files up to 10MB</h5>
                        <a href='https://www.ilovepdf.com/powerpoint_to_pdf' target='_blank' >
                            <h5 className={Classes.text3}
                            // style={{ color: isDraggingOver ? 'green' : '#0262DF' }} 
                            >Convert PPT to PDF here</h5>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UploadPopup
