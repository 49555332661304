import LogoutIcon from "@mui/icons-material/Logout";
import React, { useEffect, useState } from "react";
import { LinearProgress, Paper, Button, Modal } from '@mui/material';
import StructureIcon from '@mui/icons-material/AccountBalance';
import MarketIcon from '@mui/icons-material/ShowChart';
import BusinessIcon from '@mui/icons-material/MonetizationOn';
import CloseIcon from '@mui/icons-material/Close';

import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { authActions } from "../redux-store";
import Typography from "@mui/material/Typography";
import TelegramIcon from "@mui/icons-material/Telegram";
import NotesIcon from "@mui/icons-material/Notes";
import FolderIcon from "@mui/icons-material/Folder";
import backend from "../api";
import HistoryIcon from "@mui/icons-material/History";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import sessionstorage from "sessionstorage";
import { setChatHistoryId } from "../redux-store/auth/actions";
import { setSidebar } from "../redux-store/auth/actions";
import DashboardIcon from '@mui/icons-material/Dashboard';
import './Sidebar.css'
import Classes from './Sidebar.module.css'
import UpgradePopup from "./UpgradePopup/UpgradePopup";

const drawerWidth = 265;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto', // Adjust width as needed
  maxWidth: '70vw', // Maximum width of the modal is 80% of the viewport width
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto', // Enable scrolling if content is too long
  maxHeight: '90vh', // Maximum height is 80% of the viewport height
  margin: '5vh', // Adds margin to the top & bottom of the modal (5% of the viewport height)
  borderRadius: "15px",

};

// The rest of your components remain unchanged

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navBtn = sessionstorage.getItem("navBtn")
  const [selectedButton, setSelectedButton] = React.useState(navBtn ? navBtn : "Pitch Deck Dashboard");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  console.log(user);
  const [loading, setLoading] = React.useState(false);
  const BotID = localStorage.getItem('projectID');
  const ProjectName = localStorage.getItem('projectName');
  const ProjectID = localStorage.getItem("projectID")

  const [pitchDeckScore, setPitchDeckScore] = useState('');
  const [deckStructureScore, setDeckStructureScore] = useState('');
  const [marketPotentialScore, setMarketPotentialScore] = useState('');
  const [businessModelScore, setBusinessModelScore] = useState('');

  const [deckStructureParagraph, setDeckStructureParagraph] = useState('');
  const [marketPotentialParagraph, setMarketPotentialParagraph] = useState('');
  const [businessModelParagraph, setBusinessModelParagraph] = useState('');
  const sideBarValue = useSelector((state) => state.sidebar.sidebarValue);
  const deckId = useSelector((state) => state.deckId.deckId);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openUpgradePopup, setopenUpgradePopup] = useState(false);
  const closeUpgradePopup = () => {
    setopenUpgradePopup(false);
  }

  const navigateTo = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const NavigateToBots = async () => {
    sessionstorage.setItem("navBtn", "")
    const id = localStorage.getItem('projectID');
    await navigateTo(`/chatbots/${id}`)
  };

  console.log(sideBarValue)
  //  useEffect(() => {


  //   // Update component state based on sideBarValue
  //   if (sideBarValue) {
  //     // Adjust these according to the actual structure of sideBarValue
  //     setPitchDeckScore(sideBarValue?.pitchDeckScore);
  //     setDeckStructureScore(sideBarValue?.deckStructureScore);
  //     setMarketPotentialScore(sideBarValue?.marketPotentialScore);
  //     setBusinessModelScore(sideBarValue?.businessModelScore);
  //     setDeckStructureParagraph(sideBarValue?.deckStructureParagraph);
  //     setMarketPotentialParagraph(sideBarValue?.marketPotentialParagraph);
  //     setBusinessModelParagraph(sideBarValue?.businessModelParagraph);
  //   }


  // }, [sideBarValue]); // Re-run when sideBarValue changes


  const saveChat = async () => {
    const userId = user._id

    try {
      const body = {
        UserId: userId,
        projectId: ProjectID
      };

      const response = await backend.post("/chats", body);
      // setLoading(false);
      console.log(response)
      const RouteID = response?.data?.data?._id
      // localStorage.setItem("chatId", RouteID)
      dispatch(setChatHistoryId(RouteID))
      await UpdateProjectById(RouteID)
      // navigateTo(`/chat/${ProjectID}/${RouteID}`)

    } catch (error) {
      console.error(error);
      // toast.error("Error saving story");
    }

  };

  const UpdateProjectById = async (newChatData) => {

    console.log(newChatData)

    const id = await user?._id;
    try {
      // Fetch the existing project data
      setLoading(true);
      const response = await backend.get(`/mainfolder/${ProjectID}`);
      console.log(response?.data)
      const existingProjectData = response?.data;

      // Modify the chats array by merging new data with the existing data
      const updatedChats = [...existingProjectData.chats, newChatData];

      // Prepare the updated project body
      const body = {
        ...existingProjectData,
        chats: updatedChats,
      };
      // console.log(typeof(ProjectID))
      // Make the PUT request to update the project
      const resp = await backend.put(`/mainfolder/${ProjectID}`, body);

      console.log(resp)
      setLoading(false);
      // Optionally, you can return the updated project data
      navigateTo(`/chat/${ProjectID}/${newChatData}`)
    } catch (error) {
      // Handle error
      console.error("Error updating project:", error.message);
      throw error;
    }
  };

  const s3FileUrl = localStorage.getItem('pitch');


  useEffect(() => {
    if (selectedButton === 'Business Chatbot') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/chatbot`)
    }
    if (selectedButton === 'Pitch Deck Analyzer') {
      if (s3FileUrl) {
        sessionstorage.setItem("navBtn", selectedButton)

        dispatch(setChatHistoryId(''))
        // navigateTo(`/analyzer`)
        navigateTo(`/analyzer/${deckId}`)
      } else {
        sessionstorage.setItem("navBtn", "Pitch Deck Dashboard")
        setSelectedButton("Pitch Deck Dashboard")

        dispatch(setChatHistoryId(''))
        navigateTo(`/dashboard`)
      }

    }
    if (selectedButton === 'Chat') {
      // navigateTo('/chat')
      sessionstorage.setItem("navBtn", selectedButton)
      saveChat();
    }

    if (selectedButton === 'Pitch Deck Editor') {
      sessionstorage.setItem("navBtn", selectedButton)
      dispatch(setChatHistoryId(''))
      navigateTo(`/pitcheditor/`)
    }
    if (selectedButton === 'Pitch Deck Dashboard') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/dashboard`)
    }
    if (selectedButton === 'Pitch Deck Templates') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/templates/`)
    }
    if (selectedButton === 'Testing') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/testing/`)
    }
    if (selectedButton === 'Testing-1') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/testing1/`)
    }


  }, [selectedButton])

  const handleLogout = async () => {
    localStorage.clear();
    sessionstorage.clear();
    navigateTo("/");
    await dispatch(authActions.logout());
    // alert("Signed out successfully")
  };


  const handleHomeClick = async () => {
    sessionstorage.setItem("navBtn", 'Pitch Deck Dashboard')
    sessionstorage.setItem("refresh", 'yes')
    navigateTo("/");
    // alert("Signed out successfully")
  };

  const ProgressWithLabel = ({ value, label }) => (
    <Box display="flex" alignItems="center">
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{label}</Typography>
      </Box>
      <Box width="100%" mx={2}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );

  const ScoreProgress = ({ value, IconComponent, label }) => {
    const numericValue = parseFloat(value); // Convert string value to number

    // Determine color based on value
    let color;
    if (numericValue >= 100) {
      color = 'green'; // Strong
    } else if (numericValue >= 80) {
      color = 'lightgreen'; // Low strong
    } else {
      color = '#F44336'; // Weak or normal
    }

    // Determine the color of the LinearProgress based on the score
    const progressColor = (value) => {
      if (value >= 100) return 'green'; // Strong
      else if (value >= 80) return 'lightgreen'; // Low strong
      else return '#F44336'; // Weak or normal
    };

    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <img src={`/icons/${IconComponent}`} style={{ width: "23px", height: "23px", paddingRight: "1rem" }} />
          <p style={{ color: "black", fontWeight: "600" }}>{label}</p>
        </div>
        <Box display="flex" alignItems="center" >
          <Box width="100%" mr={1} >
            <LinearProgress
              variant="determinate"
              value={numericValue}
              sx={{
                '& .MuiLinearProgress-barColorPrimary': {
                  backgroundColor: progressColor(numericValue),
                },
                backgroundColor: '#eee', // Background color of the progress bar
              }}
            />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(numericValue)}/100`}</Typography>
          </Box>
        </Box>
      </div>
    );
  };
  const drawer = (
    <div style={{
      background: "white",
      // color: "rgba(255, 255, 255, 0.6)",
      minHeight: "100vh",
      // width: "360px", // Set a fixed width for the sidebar
    }}
      className="element"
    >
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          background: "transparent",
        }}
      >
        <a onClick={() => handleHomeClick()} style={{ marginTop: "2rem", marginLeft: "0rem", cursor: "pointer", marginBottom: "2rem" }}>
          <img src="/logoblue.png" alt="logo" style={{ width: "190px", height: "30px" }} />
        </a>
      </Toolbar>

      <div className="li-items545" >
        <List>
          {[
            "Pitch Deck Dashboard",
            "Pitch Deck Templates",
            "Pitch Deck Analyzer",
            "Business Chatbot"

          ].map((text, index) => (
            <ListItem key={text} style={{
              width: "100%", 
              // background:
              //   selectedButton === text
              //     ? "#D6E4F8"
              //     : "transparent",
              // borderRight: selectedButton === text ? "6px solid #0262DF" : "",
              // height:"50px",
              padding: "0.45rem 0.5rem"

            }}>
              <ListItemButton
                onClick={() => { index === 2 && !s3FileUrl ? setSelectedButton("Pitch Deck Dashboard") : setSelectedButton(text) }}
                style={{
                  background: "transparent",
                  color: "black",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  padding: "0rem 1rem"
                }}
                disabled={index === 10 && !s3FileUrl ? true : false}
                disableRipple // Disables the ripple effect
                disableTouchRipple // Disables the ripple effect on touch devices
              >
                <ListItemIcon style={{ padding: "0rem 0rem" }}>
                  {index === 0 ? (
                    <img src={selectedButton === text ? "/icons/dashboard.png" : "/icons/dashboard-dark.png"} alt="dashboard"
                      style={{
                        width: "17px",
                        height: "17px",

                      }}
                    />
                  ) : index === 1 ? (
                    <img src={selectedButton === text ? "/icons/deck.png" : "/icons/templates-dark.png"} alt="templates"
                      style={{
                        width: "19px",
                        height: "17px",
                      }}
                    />
                  ) : index === 2 ? (
                    <img src={selectedButton === text ? "/icons/analyzer.png" : "/icons/analyzer-dark.png"} alt="analyzer"
                      style={{
                        width: "19px",
                        height: "19px",
                      }}
                    />
                  ) : index === 3 ? (
                    <img src={selectedButton === text ? "/icons/coach.png" : "/icons/coach-dark.png"} alt="coach"
                      style={{
                        width: "17px",
                        height: "18px",
                      }}
                    />
                  ) : index === 4 ? (
                    <img src="/icons/presentation.png" alt="presentation"
                      style={{
                        width: "17px",
                        height: "17px",
                      }}
                    />
                  ) : index === 5 ? (
                    <img src="/CustomQA.png" alt="Custom Q&A"
                      style={{
                        width: "17px",
                        height: "17px",
                      }}
                    />
                  ) : index === 5 ? (
                    <img src="/inbox.png" alt="Inbox Icon"
                      style={{
                        width: "17px",
                        height: "17px",
                      }}
                    />
                  ) : (
                    <FolderSpecialIcon />
                  )}
                </ListItemIcon>
                {/* <Typography
                  style={{
                    fontWeight: selectedButton === text ? "550" : "400",
                    fontSize: "16px",
                    fontFamily: "Poppins, sans-serif",
                    color: selectedButton === text ?"#0262DF":'black',
                  }}
                >
                  {text} {index === 3||index===4?<img src="/icons/crown.png" style={{width:"14px", height:"14px", paddingLeft:"0.5rem"}}/>:null}
                </Typography> */}
                <Typography
                  style={{
                    fontWeight: selectedButton === text ? "600" : "600",
                    fontSize: "14px",
                    fontFamily: "Poppins, sans-serif",
                    color: selectedButton === text ? "#0262DF" : 'black',
                    marginLeft: "-1.8rem"
                  }}
                >
                  {text}

                </Typography>
              </ListItemButton>

            </ListItem>
          ))}

        </List>
        <br />
      </div>
      <div style={{ paddingBottom: "1rem", position: "absolute", bottom: 0, width: '100%' }}>
        {openUpgradePopup && <UpgradePopup closeUpgradePopup={closeUpgradePopup} closeable={true} />}

        {
          user.subscription === 'free' ?
            <div className={Classes.premiumBox} >
              <h5>Unlock all features to perfect your pitch deck</h5>
              <div onClick={() => { setopenUpgradePopup(true) }} className={Classes.premiumBtn} >
                <img src="/icons/premium.png" alt="premium icon" />
                <span>Upgrade to premium</span>
              </div>
            </div> : null
        }

        <div style={{ paddingLeft: "1rem", paddingRight: '1rem' }} >
          <Divider style={{ margin: '0 9px 0 9px', marginBottom: "1rem" }} />
          <a href="/contact-us" style={{ cursor: "pointer", display: "flex", alignItems: "center", marginBottom: "1rem" }}>
            <img src="/icons/support.png" style={{ color: "black", width: "19px", height: "19px", marginLeft: "0.5rem" }} />
            <a style={{ fontSize: "15px", paddingLeft: "0.8rem", color: "black", fontWeight: "500" }}>Support</a>
          </a>

          <div style={{ cursor: "pointer", display: "flex", alignItems: "center", marginBottom: "1rem" }}>
            <img src="/icons/billing.png" style={{ color: "black", width: "19px", height: "19px", marginLeft: "0.5rem" }} />
            <a href="https://billing.stripe.com/p/login/14k7vweFEeiNf9meUU" style={{ fontSize: "15px", paddingLeft: "0.8rem", color: "black", fontWeight: "500" }}>Billing</a>
          </div>

          <div style={{ cursor: "pointer", display: "flex", alignItems: "center", marginBottom: "1rem" }} onClick={() => handleLogout()}>
            <img src="/icons/logout.png" style={{ color: "black", width: "17px", height: "18px", marginLeft: "0.7rem" }} />
            <a style={{ fontSize: "15px", paddingLeft: "0.8rem", color: "black", fontWeight: "500" }}>Logout</a>
          </div>
        </div>
      </div>
    </div>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: "flex" }} className="element">
      {/* <CssBaseline /> */}
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        style={{ background: "transparent", color: "black", height: "0px" }}
      >
        {/* <Divider /> */}
        <Toolbar sx={{ mr: 2, display: { sm: "none" } }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
        style={{
          // boxShadow: "5px 0px 10px rgba(0, 0, 0, 0.07)", // Adding box shadow to the right
          borderRight: '0px solid transparent',
          border: 'none',
          minHeight: "100vh",
          zIndex: '5',
        }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: '0px solid transparent',
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: '0px solid transparent',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {/* <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar  />
        <Dashboard />
      </Box> */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          // pt: selectedButton === 'Business Chatbot' ? 0 : 5,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {/* <Toolbar />
      <Dashboard />
      <MenuFooter /> */}
        {/* {
        props.component
      } */}

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              minHeight: "100vh",
              alignItems: "center",
            }}
          >
            {/* <CircularProgress /> */}
            <img src="/loading-2.gif" alt="gif" />
          </div>
        ) : (
          props.component
        )}
      </Box>
      {/* Include your MenuFooter component here */}
    </Box>

  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
