import { applyMiddleware, combineReducers, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
import {
  authReducer
} from '.'
import types from './types'
import editorReducer from './auth/EditorReducer'
import responseReducer from './auth/responseReducer'
import responseReducer2 from './auth/responseReducer2'

import promptReducer from './auth/PromptReducer'
import chatHistoryIdReducer from './auth/ChatHistoryIdReducer'
import stepperReducer from './auth/Stepper'
import languageReducer from './auth/Language.reducer'
import sideBarReducer from './auth/SidebarReducer'
import deckIdReducer from './auth/DeckIdReducer'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const userFromStorage = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user'))
  : null

const initialState = {
  auth: {
    user: userFromStorage,
  },
}

const appReducer = combineReducers({
  auth: authReducer,
  editor: editorReducer,
  responseText: responseReducer,
  responseText2: responseReducer2,
  promptText: promptReducer,
  chatId: chatHistoryIdReducer,
  stepperValue: stepperReducer,
  language: languageReducer,
  sidebar: sideBarReducer,
  deckId: deckIdReducer
})

const rootReducer = (state, action) => {
  if (action.type === types.USER_LOGOUT) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk))
)

export default store
