import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Classes from './SectionD.module.css';
import { AnimateTopToBottom } from './Animations/AnimateTopToBottom';

const SectionD = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const sectionRef = useRef(null); // Reference to the section

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Add the animation class when the section is in the viewport
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-on-scroll-c');
        }
      },
      {
        root: null, // relative to the viewport
        rootMargin: '0px',
        threshold: 0.1, // trigger the callback when 10% of the element is visible
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section id="features" ref={sectionRef} className={Classes.section}>
      <AnimateTopToBottom delay={0.3}>
        <Button variant="contained" className="howItWorksBtn" > Features </Button>
      </AnimateTopToBottom>
      <AnimateTopToBottom delay={0.4}>
        <h1 className={Classes.heading}> Make your pitch deck stand out </h1>
      </AnimateTopToBottom>
      <AnimateTopToBottom delay={0.5}>
        <div className={Classes.grid}>
          <div className={`${Classes.item} ${Classes.textCon}`}>
            <h1 className={Classes.subheading}> Browse through <span className={Classes.colored}> hundreds of slides </span> </h1>
            <p className={Classes.text}>
              Download our premium pitch deck template and choose from hundreds of professionally designed slides to create your own successful pitch deck.
            </p>
          </div>
          <div className={`${Classes.item} ${Classes.ImgCon}`}>
            <img src='/1.png' alt='image' className={Classes.image} />
          </div>
        </div>
      </AnimateTopToBottom>
      <AnimateTopToBottom delay={0.3}>
        <div className={`${Classes.grid} ${Classes.gridReverse}`}>
          <div className={`${Classes.item} ${Classes.ImgCon}`}>
            <img src='/2.png' alt='image' className={Classes.image} />
          </div>
          <div className={`${Classes.item} ${Classes.textCon}`}>
            <h1 className={Classes.subheading}> <span className={Classes.colored}> Slide-by-slide analysis </span> of your pitch deck </h1>
            <p className={Classes.text}>
              Upload your pitch deck and our AI will analyze it, providing actionable feedback and suggestions and a score based on three categories to help you refine your pitch deck.
            </p>
          </div>
        </div>
      </AnimateTopToBottom>
      <AnimateTopToBottom delay={0.3}>
        <div className={Classes.grid}>
          <div className={`${Classes.item} ${Classes.textCon}`}>
            <h1 className={Classes.subheading}> Ask questions and our<br /> <span className={Classes.colored}> VC-AI will answer them </span> </h1>
            <p className={Classes.text}>
              Chat with our AI business chatbot, trained on over 20,000 VC blogs and pitch decks, and get answers about your pitch deck, business, and the questions you’ll be asked by judges and investors.
            </p>
          </div>
          <div className={`${Classes.item} ${Classes.ImgCon}`}>
            <img src='/3.png' alt='image' className={Classes.image} />
          </div>
        </div>
      </AnimateTopToBottom>
      {/* Repeat similar div components for other sections */}
    </section>
  );
};

export default SectionD;
