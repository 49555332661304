import React, { useState, useEffect } from 'react'
import Classes from './styles/style.module.css'
import { AnimateTopToBottom } from '../Home-New/Components/Animations/AnimateTopToBottom';
import { useDispatch, useSelector } from "react-redux";
import backend from '../../api';

function ContactUs() {
    const { user } = useSelector((state) => state.auth);
    const [sendMsg,setsendMsg] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);
    const [formdata, setformdata] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });
    const handleChange = (event) => {
        const { name, value } = event.target;
        setformdata({
            ...formdata,
            [name]: value,
        })
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        const body = {
          fullName: formdata.name,
          subject: formdata.subject,
          email: formdata.email,
          message: formdata.message,
        };
    
        if(formdata.name !="" && formdata.email !="" && formdata.message !="" && formdata.email.includes("@") && formdata.subject!="") {
        try {
          const response = await backend.post("/contact", body);
          console.log(response)
          setformdata({
            name: '',
            email: '',
            subject: '',
            message: '',
          })
          if(response ) {
             await backend.post("/email/acknowledge", body);
             setsendMsg(true);
              setTimeout(() => {
            setsendMsg(false);
        }, 30000);
          }
        } catch (error) {
          console.log(error)
          alert('Network error')
        }
      } else {
        alert('All fields are required')
      }
      };
      

    return (
        <>
            <AnimateTopToBottom delay={0.3}>
                {user &&
                    <div className={Classes.navContainer} style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} >
                        <div>
                            <a className={Classes.logoLink} href="/">
                                {width <= 480 ?
                                    <img className={Classes.minilogoImage} src='/icons/miniLogo.png' alt="Logo" />
                                    :
                                    <img className={Classes.logoImage} src='/Blue.png' alt="Logo" />
                                }
                            </a>
                        </div>
                        <nav className={Classes.navMenu}>

                        </nav>
                        <div>
                            <a href="/" className={Classes.flexAlign} >
                                <h5 className={Classes.goBackTxt} >Dashboard</h5>
                                <img src='/icons/arrow-right.png' />
                            </a>
                        </div>
                    </div>
                }
            </AnimateTopToBottom>
            <AnimateTopToBottom delay={0.3}>

                <form className={Classes.hero} >
                    <h1>Contact us</h1>
                    <div className={Classes.form_con} >
                        <div className={Classes.form_con_row} >
                            <input
                                className={Classes.input_field}
                                placeholder='Name'
                                name='name'
                                type='text'
                                required
                                value={formdata.name}
                                onChange={handleChange}
                            />
                            <input
                                className={Classes.input_field}
                                placeholder='Email Address'
                                name='email'
                                type='email'
                                required
                                value={formdata.email}
                                onChange={handleChange}
                            />
                        </div>
                        <input
                            className={Classes.input_field}
                            placeholder='Subject'
                            name='subject'
                            type='text'
                            required
                            value={formdata.subject}
                            onChange={handleChange}
                        />
                        <textarea
                            rows={10}
                            className={Classes.input_field}
                            placeholder='Message'
                            name='message'
                            type='text'
                            value={formdata.message}
                            onChange={handleChange}
                        />

                        <div className={Classes.btnCon} >
                            <button onClick={(e)=>handleSubmit(e)}> <h5 className={Classes.m0} >Send message</h5> </button>
                            { sendMsg &&
                                <div className={Classes.msgCon} >
                                    <img className={Classes.checkmark} src='./icons/check-mark.png' />
                                    <h5> Your inquiry was successfully received. We will get back to you within 48 hours. </h5>
                                </div>
                            }
                        </div>

                    </div>
                </form>
            </AnimateTopToBottom>
        </>
    )
}

export default ContactUs
