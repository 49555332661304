import React, { useEffect, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';

// import './FAQs.css'; // This is where you'll write your CSS
import Classes from './Faqs.module.css'
import { AnimateTopToBottom } from './Animations/AnimateTopToBottom';

const FAQs = () => {
  const sectionRef = useRef(null); // Reference to the section

  const faqData = [
    {
      question: 'What is PitchPerfecter.ai?',
      answer: 'PitchPerfecter.ai is an all-in-one platform, leveraging AI to simplify the creation and optimization of pitch decks and presentations. Our goal is to ensure your pitch deck stands out and attracts potential investors.',
    },
    {
      question: 'How does the AI model work?',
      answer: 'The model is trained by analyzing over 20,000 VC blogs, investment memos, and successful pitch decks.'
    },
    {
      question: 'Is your AI model trained on user data?',
      answer: 'No, we do not use user data to train our AI model.'
    },
    {
      question: 'How do you ensure privacy?',
      answer: (
        <div>
          Privacy is our top priority. You can refer to our comprehensive privacy policy at <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: '#0262DF' }}>https://pitchperfecter.ai/privacy-policy/</a>.
        </div>
      )
    },
    {
      question: 'How can I get in touch with support?',
      answer: (
        <div>
          You can contact us at <a href="/contact-us" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: '#0262DF' }}>https://pitchperfecter.ai/contact-us/</a>
        </div>
      )
    }
    // ... Add the rest of your FAQ data here
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Add the animation class when the section is in the viewport
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-on-scroll-c');
        }
      },
      {
        root: null, // relative to the viewport
        rootMargin: '0px',
        threshold: 0.1, // trigger the callback when 10% of the element is visible
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section id='faq' ref={sectionRef}>
      <div className={Classes.faqContainer} >
        <AnimateTopToBottom delay={0.2} >
          <Button variant="contained" className='howItWorksBtn' > FAQS </Button>
        </AnimateTopToBottom>
        <AnimateTopToBottom delay={0.3} >
          <h1>Frequently asked questions</h1>
        </AnimateTopToBottom>
        <AnimateTopToBottom delay={0.4} >
          <div className={Classes.qnaCon} >
            {faqData.map((faq, index) => (
              <Accordion key={index} className={Classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                >
                  <Typography style={{ fontWeight: "600" }}>{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ textAlign: "left" }}>
                  <Typography>
                    {faq.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </AnimateTopToBottom>
      </div>
    </section>

  );
};

export default FAQs;
