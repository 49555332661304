import React, { useState, useEffect } from 'react'
import Classes from './styles/style.module.css'
import {
    Button,
    ListItem,
    ListItemIcon,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { AnimateTopToBottom } from '../../Pages/Home-New/Components/Animations/AnimateTopToBottom';
import StripeCheckout from 'react-stripe-checkout';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import backend from '../../api';
import { setUserData } from "../../redux-store/auth/actions";
import axios from 'axios';

const STRIPE_PUBLISHABLE_KEY = "pk_test_51OpvvzErETeoVarWrlYX14WMIesVbgO01bqA89cOdgJBWrmSMYwEulAFElxUHAiymglfZ3skFoRigRL8h7iab9wd00wxFoCHa6"

function PurchaseUploadsPopup({ closePurchaseUploadsPopup }) {
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const onToken = (amount, description) => (token) => {
        // debugger
        const headers = {
          "Content-Type": "application/json",
        };
        backend
          .post(
            "/payment",
            { description, token: token, amount: amount },
            { headers: headers }
          )
          .then((response) => {
            alert("payment success");
            onPayment();
          })
          .catch((err) => {
            console.log("error: ", err);
            alert("payment failure", err);
          });
      };

  const onPayment = async() => {
    
    const UserToken = user?.token;
    const UserExists = user?.exists;
    const newRD = user.remainingDecks + 10;
    const email=user.email;
    const body = {
      subscription: "monthly",
      remainingDecks: newRD
    }

    // const response =  await backend.put(`/api/user/${user._id}`, body);
      const response = await axios.post(`https://pitchperfecterai-257a6e04dc34.herokuapp.com/create-checkout-session`, {
      subscription: "monthly",
      remainingDecks: newRD,
      email,
      userId: user._id,
      type:'one-time',
    })

    const url = response.data.url;
    window.location.href = url;
  }

    const [width, setWidth] = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const renderFeature = (included, text) => (
        <ListItem style={{ padding: "0.1rem" }}>
            <ListItemIcon>
                {included ? (
                    <CheckIcon style={{ color: '#0262DF', marginLeft: "-0.3rem", lineHeight: width > 1300 ? "0rem" : "1rem" }} />
                ) : (
                    <ClearIcon style={{ color: '#0262DF', marginLeft: "-0.3rem", lineHeight: width > 1300 ? "0rem" : "1rem" }} />
                )}
            </ListItemIcon>

            <p className={Classes.card1_p} >{text}</p>
        </ListItem>
    );

    const handleBgClick = () => {
        closePurchaseUploadsPopup();
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    return (
        <div className={Classes.popup} onClick={handleBgClick} >
            <div className={Classes.card} onClick={(event) => { handleCardClick(event) }} >
                <div className={Classes.close} onClick={closePurchaseUploadsPopup} >
                    <img src='/icons/close.png' alt='close icon' />
                </div>
                <div className={Classes.header} >
                    <img src='/icons/add-icon.png' alt='add-icon' />
                    <h2 className={Classes.planName}>Out of uploads</h2>
                    <h5>Purchase more uploads to continue</h5>
                </div>
                <h1 className={Classes.price} >$10<span className={Classes.priceSuffix}>for 10 uploads</span></h1>

                {/* <div className={Classes.premiumBtn} >
                    <h5>Purchase more uploads</h5>
                </div> */}
            <button onClick={onPayment} className={Classes.premiumBtn}>Purchase more uploads</button>
            </div>
        </div>
    )
}

export default PurchaseUploadsPopup
