import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { motion, easeOut } from "framer-motion";

import { Typography, Button } from '@mui/material';
import sessionstorage from 'sessionstorage';

import Classes from "./SectionA.module.css";
import '../Home-new.css'

function SectionA() {
  const [width, setWidth] = useState(window.innerWidth);
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const navigateToUpload = async () => {
    await sessionstorage.setItem("navBtn", "Pitch Deck Dashboard")

    if (user) {

      navigate('/dashboard')
    } else {
      navigate('/login')
    }

  }

  const goToDemo = () => {
    navigate('/demo');
  }

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <section id='home' className={Classes.home} >
      <div className={Classes.customBox}>
        <>
          {/* <motion.div
          initial={{ opacity: 0, y: 500 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.6,
            ease: easeOut,
            delay: 2
          }}
        >
          <Button variant="contained" className='comingSoonBtn' >
            <EmojiEventsIcon style={{ paddingRight: "1rem" }} /> Pitch Competitions Coming Soon
          </Button>
        </motion.div> */}
        </>
        <motion.div
          initial={{ opacity: 0, y: 450 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: .5,
            ease: easeOut,
            delay: 1.7
          }}
        >
          <Typography variant="h3" component="h1" className={Classes.Txt1} >
            {/* Instant slide-by-slide */}
            Instant pitch deck
          </Typography>

          {/* <Typography variant="h3" component="h2" >
            <div className={Classes.flex} >
              <span className={Classes.Txt1} > pitch deck </span>
              <span>
                <div className={Classes.container} >
                  <div className={Classes.animation}>
                    <div className={Classes.first}><div >feedback</div></div>
                    <div className={Classes.second}><div >suggestions</div></div>
                    <div className={Classes.third}><div >scoring</div></div>
                  </div>
                </div>
              </span>
            </div>
          </Typography> */}
          <Typography variant="h3" component="h2" >
            <div className={Classes.flex} >
              {/* <span className={Classes.Txt1} > Instant pitch deck </span> */}
              <span>
                <div className={Classes.container} >
                  <div className={Classes.animation}>
                    <div className={Classes.first}><div>feedback</div></div>
                    <div className={Classes.second}><div >suggestions</div></div>
                    <div className={Classes.third}><div >scoring</div></div>
                  </div>
                </div>
                {/* AI precision */}
              </span>
            </div>
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 300 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: .5, // Total duration of the animation
            ease: easeOut, // Custom easing curve
            delay: 2.2 // Delay before animation starts
          }}
        >
          {/* Typography for the description */}
          <Typography className={Classes.Txt2} >
            Designed to help early stage startups create and perfect their pitch deck, leading to increased<br /> chances of winning competitions and attracting investors
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 300 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: .5, // Total duration of the animation
            ease: easeOut, // Custom easing curve
            delay: 2.8 // Delay before animation starts
          }}
          className={Classes.home_btns_con}
        >
          <div className={Classes.home_btns}>
            <a onClick={() => navigateToUpload()}> <Button className='pitchDeckBtn'> Get started for free </Button> </a>
            <p>OR</p>
            <a > <Button className='browseBtn' onClick={goToDemo} > View demo </Button> </a>
          </div>
          <p>Try for free. No credit card required</p>
        </motion.div>
      </div>

      {width > 700 ? (
        <div className={`${Classes.bgContainer} ${Classes.large}`}>
          <img src="/bg-a.png" />
        </div>
      ) : (
        <div className={Classes.bgContainer}>
          <img src="/bg-a.png" />
        </div>
      )}
    </section>
  );
}

export default SectionA;
